import React, {
  FunctionComponent as Component,
  useEffect,
  useState,
  lazy,
  Suspense,
  useRef,
  ReactNode,
} from "react";
import { useLocation, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { CardInterface } from "./category-interface";
import "../../assets/css/product-card.css";
import Modals from "../modal";
import { ProductImage } from "./product-image";
import { RootState } from "../../store/root-reducer";
import {
  useWishlist,
  useWishlistHandle,
} from "../../pages/whishlist/components/wishlist-hooks";
import ToastNotification from "../toast-notification";
import { useProductDetail } from "../../pages/product-detail/components/product-detail-hooks";
export const ProductCard: Component<CardInterface> = (props: any) => {
  const {
    name,
    weightRange,
    level_2_category,
    bom_factory_code,
    market_design_name,
    image,
    purity,
    net_weight,
    weight,
    redirectUrl,
    variants,
    size,
    color,
    setRev,
    variant_of,
    showSidebar,
    codesArray,
    custom_size,
    tag,
    item_group,
  } = props;

  const [hasBroadness, setHasBroadness] = useState(false);
  const [storeLength, setStoreLength] = useState(0);
  const location = useLocation();
  console.log(location, "location");
  var [ascending, setAscending] = useState<any>([]);
  const {
    handleWhishlist,
    wishlistData,
    isRevaled,
    hideshow,
    show1,
    setShow1,
    show2,
    setShow2,
  } = useWishlistHandle();
  const params = useParams();
  // console.log("this is arc params", params);

  useEffect(() => {
    let tempArr: any;
    // console.log("check for variants", variants);
    if (variants) {
      tempArr = [...variants];
      tempArr = tempArr.sort(function (a: any, b: any) {
        return a.Weight - b.Weight;
      });
      setAscending([...tempArr]);
      // console.log("ascending sort", ascending);
      const hasBroadness = tempArr?.some((obj: any) =>
        Object.keys(obj).includes("Broadness")
      );
      if (hasBroadness) {
        // console.log("asce There is an object with key 'Broadness' in the array.");
        setHasBroadness(true);
      } else {
        setHasBroadness(false);
        // console.log("asce No object with key 'Broadness' found in the array.");
      }
    } else {
      ascending = [];
    }
    setStoreLength(weight / parseInt(size));
  }, [variants]);

  let productCodes: string[] = [];

  const check = () => {
    hideshow(name);
    // console.log("check");
    console.log(
      "val",
      productCodes.find((item) => item === name)
    );
  };

  // if(!localStorage.getItem("product-codes"))
  // {

  // }
  // else{
  //   productCodes = []
  // }
  if (localStorage.getItem("product-codes")) {
    productCodes = JSON.parse(localStorage.getItem("product-codes") || "");
  }

  // const testFunc = (weight: any, broadness: any) => {
  //   console.log("test function", weight, broadness);
  // };
  console.log("{ascending", ascending);
  const { productdetail, productPrevNextData } = useProductDetail();
  return (
    <>
      <ToastNotification
        setShow={setShow1}
        show={show1}
        content="Added To Wishlist!"
      />
      <ToastNotification
        setShow={setShow2}
        show={show2}
        content="Removed From Wishlist!"
      />

      <div
        className={`${
          !showSidebar ? "col-sm-4" : "col-sm-6"
        } col-lg-4 col-xl-3 text-center mb-3 `}
      >
        {/* className={showSidebar ? "col-sm-6  " :  " " } */}
        <div className="product-card">
          <Link to="#">
            {wishlistData[name] ? (
              <i
                className="fa fa-heart fill-heart product-card-icon"
                onClick={() => handleWhishlist(name, false)}
                style={{ fontSize: "20px", color: "red" }}
              />
            ) : (
              <i
                className="fa fa-heart-o product-card-icon"
                onClick={() => handleWhishlist(name, true)}
                style={{ fontSize: "20px", color: "red" }}
              />
            )}
          </Link>
          <div className={`${tag !== null ? "product-card-arrival" : ""}`}>
            {tag}
          </div>
          <div className="image-wrap">
            {/* <Link to={"/product-detail/" + name}> */}
            <Link to={redirectUrl ? redirectUrl : null} target="_blank">
              <Suspense fallback={<div>Loading</div>}>
                <ProductImage name={name} image={image} />
              </Suspense>
            </Link>
          </div>
          <div className="content-wrap">
            <h3>
              {/* <Link to={"/product-detail/" + name}>{name}</Link> */}
              {variant_of ? (
                <Link to={redirectUrl ? redirectUrl : null} target="_blank">
                  {variant_of}
                </Link>
              ) : (
                <Link to={redirectUrl ? redirectUrl : null} target="_blank">
                  {name}
                </Link>
              )}
            </h3>
            <h6 className="mb-2">{bom_factory_code}</h6>
            {/* {params.categoryId !== "ARC" ? (
            ) : null} */}

            {level_2_category === "BALL CHAINS" && (
              <h6 className="mb-2">{market_design_name}</h6>
            )}
            <h6 className="mb-2">Gross Wt. {weight}gm</h6>
            {(level_2_category === "MANGALSUTRA" ||
              level_2_category === "IMP PREMIUM" ||
              item_group === "STONE CHAINS") && (
              <h6 className="mb-2">Net Wt. {net_weight}gm</h6>
            )}
            <h6 className="mb-2">Size: {size}</h6>

            <div>
              {/* div for products card rendering */}
              <div className="btn-product-card">
                {/* {variant_of ? (codesArray.find((title:any)=> title === name) ? (
                  <div>
                    {ascending.map((item: any) => {
                      return codesArray.find(
                        (item_code: any) => item_code === item.item_code
                      ) ? (
                        <button type="button" className="btn disabled">
                          {item.Category} {item.Weight}
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="btn"
                          onClick={() => hideshow(item.item_code)}
                        >
                          {item.Category} {item.Weight}
                        </button>
                      );
                    })}
                  </div>
                ) : null) :null } */}
                {variant_of ? (
                  <div>
                    {ascending.map((item: any) => {
                      return codesArray.find(
                        (item_code: any) => item_code === item.item_code
                      ) ? (
                        <button
                          type="button"
                          className="btn mt-4 text-dark border var-added "
                          onClick={() => hideshow(item.item_code)}
                        >
                          {item.Category} {item.Weight || item.Broadness}
                          {/* {testFunc(item.Weight, item.Broadness)} */}
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="btn"
                          onClick={() => hideshow(item.item_code)}
                        >
                          {item.Category} {item.Weight || item.Broadness}
                          {/* {testFunc(item.Weight, item.Broadness)} */}
                        </button>
                      );
                    })}
                  </div>
                ) : codesArray.find((title: any) => title === name) ? (
                  <button
                    type="button"
                    className="btn mt-4 text-dark border added"
                    onClick={() => hideshow(name)}
                  >
                    Added
                  </button>
                ) : (
                  // <div className=" mt-4 text-dark border added ">
                  //   Added
                  // </div>
                  <button
                    type="button"
                    className="btn"
                    onClick={() => hideshow(name)}
                  >
                    ADD TO CART
                  </button>
                )}
              </div>

              {/* divs for modal */}
              <div>
                {variant_of ? (
                  ascending?.map((item: any) => {
                    return isRevaled === item.item_code ? (
                      <Modals
                        title={item.item_code}
                        show={isRevaled === item.item_code}
                        onHide={hideshow}
                        weight={item.Weight}
                        size={size}
                        setRev={setRev}
                        color={color}
                        custom_size={custom_size}
                        image={image}
                        prodCodes={codesArray}
                        bom_factory_code={productdetail?.bom_factory_code}
                        // bom_factory_code={bom_factory_code}
                        factory_name={params.categoryId}
                        level_2_category={level_2_category}
                        net_weight={net_weight}
                        market_design_name={market_design_name}
                        hasBroadness={hasBroadness}
                        storeLength={storeLength}
                      />
                    ) : null;
                  })
                ) : (
                  <Modals
                    title={name}
                    show={isRevaled === name}
                    onHide={hideshow}
                    weight={weight}
                    size={size}
                    setRev={setRev}
                    color={color}
                    image={image}
                    prodCodes={codesArray}
                    custom_size={custom_size}
                    factory_name={params.categoryId}
                    bom_factory_code={bom_factory_code}
                    level_2_category={level_2_category}
                    net_weight={net_weight}
                    market_design_name={market_design_name}
                    hasBroadness={hasBroadness}
                    storeLength={storeLength}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
