import { Button, Modal } from "react-bootstrap";

const ConfirmationModal = ({
  show,
  handleClose,
  confirmDelete,
  title,
  message,
}: any) => {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{message}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="danger" onClick={confirmDelete}>
          {title}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmationModal;
