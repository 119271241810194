import React, { FunctionComponent as Component, useState } from "react";
import { DEFAULT_API_CONFIG } from "../../services/config/api-config";
import { CardInterface } from "./category-interface";
import noimage from "./../../assets/img/nia.jpg";
import "../../assets/css/order_details.css";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalTitle,
  ModalFooter,
} from "react-bootstrap";

export const OrderDetailCard: Component<CardInterface> = (props: any) => {
  const {
    name,
    image,
    weight,
    level_2_category,
    net_weight,
    market_design_name,
    purity,
    order,
    remark,
    totalWeight,
    wastage,
    barcodeimage,
    bom_factory_code,
    status,
    setReviewState,
    callAddReviewAPI,
    callUpdateSalesOrderStatusAPI,
    customerName,
    order_id,
    order_date,
    issue_weight,
    review_value,
    getOrderStatusValueFromURL,
    soisd_item,
    reviewState,
    handleStatusChamge,
    orderId,
    showDispatchBtn,
    handleDeleteItem,
    showDeleteBtn,
  } = props;

  const [isSeen, setIsSeen] = useState(false);
  // console.log(name);

  const handleOrder = () => {
    setIsSeen(!isSeen);
  };

  const [reviewModalToggle, setReviewModalToggle] = useState<boolean>(false);
  const [errMsgforReviewSubmitBtn, setErrMsgforReviewSubmitBtn] =
    useState<boolean>(false);

  const showReviewModal = () => {
    setReviewModalToggle(!reviewModalToggle);
  };

  const handleReviewSubmitBtn: any = () => {
    if (Object.keys(reviewState)?.length > 0 || review_value !== null) {
      setErrMsgforReviewSubmitBtn(false);
      callAddReviewAPI(order_id, name, customerName, order_date);
      callUpdateSalesOrderStatusAPI(soisd_item);
      setReviewModalToggle(false);
    } else {
      setErrMsgforReviewSubmitBtn(true);
    }
  };

  const handleKeyDown = (e: any) => {
    // console.log("enter press", reviewState);
    if (e.key === "Enter") {
      handleReviewSubmitBtn();
    }
  };

  return (
    <>
      <div className="content-prev">
        <div className="col-12">
          <div className="row ">
            <div className="col-7 text-center d-flex flex-column justify-content-center text-center">
              <div className="row align-items-center border">
                <div className="col-2">
                  <div
                    className="img-wrap text-center"
                    style={{ height: "110px" }}
                  >
                    <img
                      src={image ? DEFAULT_API_CONFIG.url + image : noimage}
                      alt=""
                      width={100}
                      height={100}
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="col-4 text-start">
                  <div className="order-detail-block ">
                    <img
                      src={barcodeimage}
                      alt="barcode image"
                      width={200}
                      height={70}
                    />
                    <p className="mb-0">Product code:{name}</p>
                    <p className="fw-bold bom_code_fs">
                      BOM Factory Code: {bom_factory_code}
                    </p>
                    {level_2_category === "BALL CHAINS" && (
                      <p>Market Design Name:- {market_design_name}</p>
                    )}
                    <p className="mb-0">Weight: {parseInt(weight)} gm</p>
                    {(level_2_category === "MANGALSUTRA" ||
                      level_2_category === "IMP PREMIUM") && (
                      <p className="mb-2">Net Wt. {parseInt(net_weight)}gm</p>
                    )}
                  </div>
                  {status === "Pending For Confirmation" &&
                  getOrderStatusValueFromURL === null ? (
                    <div className="">
                      <div>
                        <button
                          className="mt-3 mb-3 order_detail_review_btn"
                          onClick={showReviewModal}
                        >
                          Add Review
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div className="">
                      {/* <div>
                        <button
                          className="mt-3 mb-3 order_detail_review_btn"
                          onClick={showReviewModal}
                        >
                          Check Review
                        </button>
                      </div> */}
                    </div>
                  )}
                </div>
                <div className="col-1 order-detail-block ">
                  <p className="mb-0">{purity}</p>
                </div>
                <div className="col-3 text-start">
                  {/* <p className="text-dark" style={{ fontSize: "14px" }}>
                    Wastage:-{wastage}
                  </p> */}
                  <p className="text-dark text-center"> {remark}</p>
                </div>
                <div className="col-2 text-start">
                  <p className="text-dark" style={{ fontSize: "14px" }}>
                    {status}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-1 border p-0 d-flex flex-column justify-content-center">
              {status === "Pending" && showDispatchBtn && (
                <div className="btn-product-card-status text-center">
                  <button
                    className="btn"
                    onClick={() => handleStatusChamge(orderId, name)}
                  >
                    Ready To Dispatch
                  </button>
                </div>
              )}

              {status === "Pending" && showDeleteBtn && (
                <div className=" text-center mt-2">
                  <button
                    className="btn btn-danger fs-10"
                    onClick={() => handleDeleteItem(orderId, name)}
                  >
                    Delete
                  </button>
                </div>
              )}
            </div>
            <div className="col-4 border p-0">
              <div className="order_detail_table">
                <table style={{ height: "100%" }}>
                  <tr>
                    <th>Color</th>
                    <th>Size(Inch)</th>
                    <th>Qty</th>
                    <th>Weight(gm)</th>
                    {/* <th>Description</th> */}
                  </tr>
                  {order.length > 0 &&
                    order.map((data: any, index: any) => {
                      return (
                        <tr key={index}>
                          <td>{data.colour}</td>
                          <td>{data.size} inch</td>
                          <td>{data.qty}</td>
                          <td className="text-right">{data.weight}gm</td>
                          {/* <td className="text-center">{data?.remark}</td> */}
                        </tr>
                      );
                    })}
                  <tr>
                    <td style={{ fontSize: "10px !important" }}>
                      Total Weight:
                    </td>
                    <td className="text-right" colSpan={3}>
                      {totalWeight.toFixed(2)} gm
                    </td>
                  </tr>

                  {issue_weight !== null && issue_weight !== "" && (
                    <tr>
                      <td style={{ fontSize: "10px !important" }}>
                        Issue Weight:
                      </td>
                      <td className="text-right" colSpan={3}>
                        {parseInt(issue_weight)} gm
                      </td>
                    </tr>
                  )}
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-12">
            <Modal
              show={reviewModalToggle}
              onHide={showReviewModal}
              contentClassName="modal"
              className=""
            >
              <ModalHeader closeButton>
                <ModalTitle>
                  <h2>Add Review for this Item</h2>
                </ModalTitle>
              </ModalHeader>
              <ModalBody>
                <div>
                  <textarea
                    rows={10}
                    cols={80}
                    onChange={(e: any) => setReviewState(e.target.value)}
                    onKeyDown={handleKeyDown}
                  >
                    {review_value && review_value}
                  </textarea>
                </div>
              </ModalBody>
              {errMsgforReviewSubmitBtn && (
                <span className="text-danger ps-3">
                  Please Add Review for this item
                </span>
              )}
              <ModalFooter>
                {/* {status === "Confirmed" &&
                  getOrderStatusValueFromURL === null && ( */}
                <button
                  className="order_detail_review_btn"
                  onClick={handleReviewSubmitBtn}
                >
                  Submit
                </button>
                {/* )} */}
              </ModalFooter>
            </Modal>
          </div>
        </div>
      </div>
    </>
  );
};
