import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { handleResponse } from "../../services/config/handle-response";
import api from "../../services/api/order-api";

interface RepoOrderState {
  orderlist: any;
  orderdetail: any;
  error: any;
}

const initialState: RepoOrderState = {
  orderlist: [],
  orderdetail: [],
  error: "",
};

const orderScreen = createSlice({
  name: "order",
  initialState,
  reducers: {
    orderSuccess(state, action: PayloadAction<RepoOrderState>) {
      state.orderlist = action.payload;
    },
    orderFailed(state) {
      state.error = "Error or Occured";
    },
    orderdetailSuccess(state, action: PayloadAction<RepoOrderState>) {
      state.orderdetail = action.payload;
    },
    orderdeatilFailed(state) {
      state.error = "Error or Occured";
    },
  },
});

const { orderSuccess, orderFailed, orderdetailSuccess, orderdeatilFailed } =
  orderScreen.actions;

export const orderAPI =
  (request: any): any =>
  async (dispatch: any) => {
    try {
      const response = await api.getOrderListAPI(request);
      const result = dispatch(handleResponse(response));
      console.log(result);
      if (result) {
        dispatch(orderSuccess(result));
      } else {
        dispatch(orderFailed());
      }
    } catch (err) {}
  };

export const PlaceOrderAPI =
  (request: any): any =>
  async (dispatch: any) => {
    try {
      const response = await api.getPlaceOrderListAPI(request);
      // console.log(response)
      const result = dispatch(handleResponse(response));
      // console.log(result)
    } catch (err) {}
  };

export const orderdetailAPI =
  (request: any): any =>
  async (dispatch: any) => {
    try {
      const response = await api.getOrderDetailListAPI(request);
      const result = dispatch(handleResponse(response));
      console.log(result);
      if (result) {
        dispatch(orderdetailSuccess(result));
      } else {
        dispatch(orderdeatilFailed());
      }
    } catch (err) {}
  };

export const changeItemStatus = async (request: any) => {
  try {
    const response = await api.changeItemStatus(request);
    console.log(response, "Response");
    if (response) {
      return response;
    }
  } catch (error) {
    console.log(error);
  }
};

export const dispatchOrderAPI =
  (request: any): any =>
  async (dispatch: any) => {
    console.log("dispatch order slice", request);
    try {
      const response = await api.postDispatchOrder(request);
      // console.log("dispatch order slice res",response);
      const result = dispatch(handleResponse(response));
    } catch (error) {
      console.log(error);
    }
  };
export const userAccessToDispatchAPI: any = async (request: any) => {
  try {
    const response = await api.postUserAccessToDispatch(request);
    if (response) {
      return response;
    }
  } catch (error) {
    console.log(error);
  }
};
export const userAccessToDeleteAPI: any = async (request: any) => {
  try {
    const response = await api.postUserAccessToDelete(request);
    if (response) {
      return response;
    }
  } catch (error) {
    console.log(error);
  }
};
export const deleteItemAPI: any = async (request: any) => {
  try {
    const response = await api.postDeleteItem(request);
    if (response) {
      return response;
    }
  } catch (error) {
    console.log(error);
  }
};

export default orderScreen.reducer;
