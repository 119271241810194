import "../App.css";
import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Login from "../pages/login/login";
import ProtectedRoute from "./ProtectedRoute";
import PublicRoute from "./PublicRoute";
import Category from "../pages/category";
import Subcategory from "../pages/subcategory";
import NoPage from "../pages/nopage/NoPage";
import Products from "../pages/products";
import ProductDetail from "../pages/product-detail";
import Wishlist from "../pages/whishlist";
import Cart from "../pages/cart/Cart";
import ReviewReportList from "../pages/reports/review-report/review-report-list";
import OrderList from "../pages/order-list";
import AllProduct from "../pages/all-products";
import OrderDetail from "../pages/order-detail";
import Ornaments from "../pages/ornaments/ornaments";
import Chains from "../pages/chains/chains";
import CompletedOrders from "../pages/completed-orders/CompletedOrders";
import Turkey from "../pages/Turkey/Turkey";
import CancelledOrders from "../pages/cancelled-orders/CancelledOrders";
import ReviewReportDetail from "../pages/reports/review-report/review-report-detail";
import Exhibition from "../pages/exhibition/exhibition";
import ReportList from "../pages/reports/report-list";
import QuickOrder from "../pages/quick-order/quick-order";
import BulkOrderList from "../pages/bulk-order";
import Export from "../pages/export/Export";

function RouteIndex() {
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <PublicRoute>
              <Login />
            </PublicRoute>
          }
        />
        <Route
          path="/categories"
          element={
            <ProtectedRoute>
              <Category />
            </ProtectedRoute>
          }
        />
        <Route
          path="/new-arrival"
          element={
            <ProtectedRoute>
              <Products />
            </ProtectedRoute>
          }
        />

        <Route
          path="/categories/ornaments"
          element={
            <ProtectedRoute>
              <Ornaments />
            </ProtectedRoute>
          }
        />
        <Route
          path="/categories/chains"
          element={
            <ProtectedRoute>
              <Chains />
            </ProtectedRoute>
          }
        />

        <Route
          path="/categories/turkey"
          element={
            <ProtectedRoute>
              <Turkey />
            </ProtectedRoute>
          }
        />
        <Route
          path="/categories/export"
          element={
            <ProtectedRoute>
              <Export />
            </ProtectedRoute>
          }
        />
        <Route
          path="/categories/exhibition"
          element={
            <ProtectedRoute>
              <Exhibition />
            </ProtectedRoute>
          }
        />
        <Route
          path="/sub-category/:categoryId"
          element={
            <ProtectedRoute>
              <Subcategory />
            </ProtectedRoute>
          }
        />
        <Route
          path="/products/:categoryId/:subCategoryId"
          element={
            <ProtectedRoute>
              <Products />
            </ProtectedRoute>
          }
        />

        <Route
          path="/product-detail/:categoryId/:subCategoryId/:productId"
          // path="/product-detail/:productId"
          element={
            <ProtectedRoute>
              <ProductDetail />
            </ProtectedRoute>
          }
        />
        <Route
          path="/product-detail/:productId"
          // path="/product-detail/:productId"
          element={
            <ProtectedRoute>
              <ProductDetail />
            </ProtectedRoute>
          }
        />
        <Route
          path="/cart"
          element={
            <ProtectedRoute>
              <Cart />
            </ProtectedRoute>
          }
        />
        <Route
          path="/whishlist"
          element={
            <ProtectedRoute>
              <Wishlist />
            </ProtectedRoute>
          }
        />
        <Route
          path="/reports/review-report"
          element={
            <ProtectedRoute>
              <ReportList />
            </ProtectedRoute>
          }
        />
        <Route
          path="/reports/pending-orders-report"
          element={
            <ProtectedRoute>
              <ReportList />
            </ProtectedRoute>
          }
        />
        <Route
          path="/reports/in-process-orders-report"
          element={
            <ProtectedRoute>
              <ReportList />
            </ProtectedRoute>
          }
        />
        <Route
          path="/reports/dispatched-orders-report"
          element={
            <ProtectedRoute>
              <ReportList />
            </ProtectedRoute>
          }
        />
        <Route
          path="/reports/due-date-reminder-report"
          element={
            <ProtectedRoute>
              <ReportList />
            </ProtectedRoute>
          }
        />
        <Route
          path="/reports/late-orders-report"
          element={
            <ProtectedRoute>
              <ReportList />
            </ProtectedRoute>
          }
        />
        <Route
          path="/reports/review-report/:salesOrderID"
          element={
            <ProtectedRoute>
              <ReviewReportDetail />
            </ProtectedRoute>
          }
        />
        <Route
          path="/order-list"
          element={
            <ProtectedRoute>
              <OrderList />
            </ProtectedRoute>
          }
        />
        <Route
          path="/quick-order"
          element={
            <ProtectedRoute>
              <QuickOrder />
            </ProtectedRoute>
          }
        />
        <Route
          path="/bulk-order"
          element={
            <ProtectedRoute>
              <BulkOrderList />
            </ProtectedRoute>
          }
        />
        <Route
          path="/completed-orders"
          element={
            <ProtectedRoute>
              <CompletedOrders />
            </ProtectedRoute>
          }
        />
        <Route
          path="/cancelled-orders"
          element={
            <ProtectedRoute>
              <CancelledOrders />
            </ProtectedRoute>
          }
        />
        <Route
          path="/order-detail/:orderId"
          element={
            <ProtectedRoute>
              <OrderDetail />
            </ProtectedRoute>
          }
        />
        <Route
          path="/all-product/:categoryId/:page"
          element={
            <ProtectedRoute>
              <AllProduct />
            </ProtectedRoute>
          }
        />
        <Route path="*" element={<NoPage />} />
      </Routes>
    </Router>
  );
}

export default RouteIndex;
