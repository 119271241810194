import { Link, useParams } from "react-router-dom";
import { ProductCard } from "../../components/cards/product-card";
import Sidebar from "../../components/product-sidebar";
import { useProducts } from "./components/product-hooks";
import { Norecord } from "../../components/norecord";
import Pagination from "../../components/pagination";
import Purity from "../../components/purity";
import Breadcrumb from "../../components/breadcrumb";
import { useSelector } from "react-redux";
import { RootState } from "../../store/root-reducer";
import { useState, useRef } from "react";
import ToastNotification from "../../components/toast-notification";
import { Variants } from "../../components/cards/product-card-dummy";

const Products = () => {
  const {
    products,
    checked,
    paginate,
    handleFilter,
    handleCheckboxChange,
    handleSortBySeq,
    totalCount,
    curretPage,
    handlePrevbtn,
    handleNextbtn,
    maxPageNumberLimit,
    minPageNumberLimit,
    customerName,
    arrayOfCodes,
    filtersArr,
    sortBySeq,
    handlesortByDesc,
    sortByDesc,
  } = useProducts();
  console.log("weight", products);
  const params = useParams();
  const productData = useSelector(
    (state: RootState) => state.products.products
  );

  const [rev, setRev] = useState(false);
  const [showSidebar, setshowSidebar] = useState(true);
  const [colorValue, setcolorValue] = useState("Yellow");

  const hideSidebar = () => {
    setshowSidebar(!showSidebar);
  };

  const handleSaveName = (name: any) => {
    console.log(name);
  };
  const handleColor = (col: string) => {
    console.log(col);
    setcolorValue(col);
  };
  console.log(products, "data111");
  return (
    <>
      <div className="breadcrumb-sec">
        <div className="container-lg">
          <div className="row">
            <div className="col-12">
              <Breadcrumb
                name={customerName}
                breadcrumbs={[
                  { to: "/categories", name: params.categoryId },
                  {
                    to: "/sub-category/" + params.categoryId,
                    name: params.subCategoryId,
                  },
                  {
                    to:
                      "/products/" +
                      params.categoryId +
                      "/" +
                      params.subCategoryId,
                    name: "Products",
                  },
                ]}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="pb-3">
        <ToastNotification
          setShow={setRev}
          show={rev}
          content="Product Added!"
        />
        <div id="body">
          <Purity
            funcColor={handleColor}
            handleSortBySeq={handleSortBySeq}
            sortBySeq={sortBySeq}
            handlesortByDesc={handlesortByDesc}
            sortByDesc={sortByDesc}
          />
          <div className="col-12">
            <div className="filter-button ms-5">
              <button onClick={hideSidebar} className="p-1">
                Show/Hide Filters{" "}
              </button>
            </div>
          </div>
          <div className="products-wrap ps-lg-5 pe-lg-4 ">
            {showSidebar ? (
              <div className="sidebar_hide">
                <Sidebar
                  handleCheckboxChange={(
                    e: any,
                    index: any,
                    type: any,
                    productPage: any
                  ) => handleCheckboxChange(e, index, type, productPage)}
                  handleFilter={handleFilter}
                  productData={productData}
                  productPage={params}
                  checked={checked}
                  filtersArr={filtersArr}
                />
              </div>
            ) : null}
            <div
              id="content"
              className={`container ${!showSidebar ? "filter-set" : ""}`}
              style={{ marginTop: "7px" }}
            >
              <div className="products row">
                {products.length > 0 && products !== null ? (
                  products.map((product: any, index: any) => {
                    let {
                      name,
                      weight_range,
                      image,
                      net_weight,
                      weight_per_unit,
                      length,
                      variant,
                      variant_of,
                      bom_factory_code,
                      market_design_name,
                      level_2_category,
                      custom_size,
                      tag,
                      item_group,
                    } = product;
                    return (
                      <ProductCard
                        showSidebar={showSidebar}
                        key={index + name}
                        name={name}
                        variant_of={variant_of}
                        level_2_category={level_2_category}
                        bom_factory_code={bom_factory_code}
                        market_design_name={market_design_name}
                        image={image}
                        weightRange={weight_range}
                        net_weight={net_weight}
                        weight={weight_per_unit}
                        size={length}
                        custom_size={custom_size}
                        variants={variant}
                        tag={tag}
                        color={colorValue}
                        redirectUrl={
                          "/product-detail/" +
                          params.categoryId +
                          "/" +
                          params.subCategoryId +
                          "/" +
                          name
                        }
                        setRev={setRev}
                        codesArray={arrayOfCodes}
                        item_group={item_group}
                      />
                    );
                  })
                ) : (
                  <Norecord />
                )}
              </div>
            </div>
          </div>
          {products !== null && products.length > 0 ? (
            <Pagination
              paginate={paginate}
              totalRecord={totalCount}
              currentPage={curretPage}
              handlePrevbtn={(paramss: any) => handlePrevbtn(paramss)}
              handleNextbtn={(paramss: any) => handleNextbtn(paramss)}
              maxPageNumberLimit={maxPageNumberLimit}
              minPageNumberLimit={minPageNumberLimit}
              productPage={params}
            />
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};
export default Products;
