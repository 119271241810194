import { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import "../../assets/css/navbar.css";
import logo from "../../assets/img/logo.png";
import Sidebar from "../sidebar";
import { useNavbar } from "./components/navbar-hooks";
import GetSearchData from "../../services/api/search-api";

const Navbar = () => {
  const { wishlistCount, cartCount, handleLogout } = useNavbar();
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();
  const param = useParams();
  const [shows, setShows] = useState(false);
  const closeSidebar = () => setShows(false);
  const openSidebar = () => setShows(true);

  console.log(cartCount);

  const handleSearch = async (e: any) => {
    e.preventDefault();
    if (searchTerm && searchTerm !== "") {
      const getSearchData = await GetSearchData(searchTerm);
      if (
        getSearchData?.status === 200 &&
        getSearchData?.data?.message?.length > 0
      ) {
        const prodID = getSearchData?.data?.message[0]?.item_code;
        navigate("/product-detail/" + prodID);
      } else {
        navigate("/product-detail/" + searchTerm);
      }
    }
    setSearchTerm("");
  };

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      handleSearch(e);
      console.log("Enter key pressed ✅");
    }
  };
  ///////////
  const getUser: any = localStorage.getItem("user");
  console.log("user updated", getUser);
  const User: any = getUser?.split('"');
  console.log("user 1", User[1]);
  const getfName = User[1].split("@");
  console.log("user n ", getfName[0]);
  return (
    <>
      <header className="fixed-top w-100">
        <nav
          className="navbar navbar-expand-lg navbar-light p-0 container-lg d-block"
          // style={{ borderBottom: "1px solid #d9c8c8" }}
        >
          <div className="d-flex">
            <div className="hamburger-icon">
              <span>
                <i className="fa fa-bars btn" onClick={openSidebar} />
              </span>
            </div>
            <Link to="/">
              <img
                className="navbar-brand img-fluid logo"
                src={logo}
                alt="logo"
                width={40}
              />
            </Link>
          </div>

          <div className="search-field">
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                placeholder="Search here"
                aria-label="Search"
                value={searchTerm}
                onChange={(e: any) => setSearchTerm(e.target.value)}
                onKeyDown={handleKeyDown}
                autoFocus
              />
              <button
                className="input-group-text"
                id="basic-addon1"
                onClick={(e: any) => handleSearch(e)}
              >
                <i className="fa fa-search" aria-hidden="true" />
              </button>
            </div>
          </div>
          <div>
            <ul className="nav align-items-center justify-content-end list-inline">
              <li className="list-inline-item">
                <Link to="/cart" className="link-dark">
                  <i
                    className="fa fa-shopping-cart"
                    style={{
                      fontSize: "25px",
                      color: "#030f27",
                    }}
                  />
                  <span className="badge badge-warning" id="lblCartCount">
                    {cartCount}
                  </span>
                  <span className="d-none d-md-inline-block">Cart</span>
                </Link>
              </li>
              <li className="list-inline-item">
                <Link to="/whishlist" className="link-dark">
                  <i
                    className="fa fa-heart"
                    style={{ fontSize: "25px", color: "#030f27" }}
                  />
                  <span className="badge badge-warning" id="lblCartCount">
                    {wishlistCount}
                  </span>
                  <span className="d-none d-md-inline-block">Wishlist</span>
                </Link>
              </li>
              {/* <li className="list-inline-item">
              <Link to="/bulk-order" className="">
              <div className="bulk-btn-nav">Bulk Order</div>
              </Link>
              </li> */}
              <li className="list-inline-item">
                <div className="dropdown">
                  <button
                    className="btn btn-secondary dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    style={{
                      background: "none",
                      border: "none",
                      marginTop: "6px",
                      color: "#030f27",
                    }}
                  >
                    My Orders
                  </button>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li>
                      <Link
                        to="/order-list"
                        className="link-dark dropdown-item"
                      >
                        <i
                          className="fa fa-list"
                          style={{ fontSize: "23px", color: "#030f27" }}
                        />
                        <span className="d-none d-md-inline-block ms-1">
                          Order List
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/completed-orders"
                        className="link-dark dropdown-item"
                      >
                        <i
                          className="fa fa-calendar-check-o"
                          style={{
                            fontSize: "23px",
                            color: "#030f27",
                            marginTop: "7px",
                          }}
                        />
                        <span className="d-none d-md-inline-block ms-1">
                          Completed Orders
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/cancelled-orders"
                        className="link-dark dropdown-item"
                      >
                        <i
                          className="fa fa-calendar-times-o"
                          style={{
                            fontSize: "23px",
                            color: "#030f27",
                            marginTop: "7px",
                          }}
                        />
                        <span className="d-none d-md-inline-block ms-1">
                          Cancelled Orders
                        </span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>

              <li className="list-inline-item">
                <div className="dropdown">
                  <button
                    className="btn btn-secondary dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    style={{
                      background: "none",
                      border: "none",
                      marginTop: "6px",
                      color: "#030f27",
                    }}
                  >
                    Reports
                  </button>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li>
                      <Link
                        to="/reports/pending-orders-report"
                        className="link-dark dropdown-item"
                      >
                        <span className="d-none d-md-inline-block ms-1">
                          Pending Orders Report
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/reports/in-process-orders-report"
                        className="link-dark dropdown-item"
                      >
                        <span className="d-none d-md-inline-block ms-1">
                          In Process Orders Report
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/reports/dispatched-orders-report"
                        className="link-dark dropdown-item"
                      >
                        <span className="d-none d-md-inline-block ms-1">
                          Dispatched Orders Report
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/reports/review-report"
                        className="link-dark dropdown-item"
                      >
                        <span className="d-none d-md-inline-block ms-1">
                          Review Report
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/reports/due-date-reminder-report"
                        className="link-dark dropdown-item"
                      >
                        <span className="d-none d-md-inline-block ms-1">
                          Due Date Reminder Report
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/reports/late-orders-report"
                        className="link-dark dropdown-item"
                      >
                        <span className="d-none d-md-inline-block ms-1">
                          Late Orders Report
                        </span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>

              {/* <li className="list-inline-item">
                <Link to="/order-list" className="link-dark">
                  <i
                    className="fa fa-list"
                    style={{ fontSize: "23px", color: "#030f27" }}
                  />
                  <span className="d-none d-md-inline-block ms-1">
                    Order List
                  </span>
                </Link>
              </li>
              <li className="list-inline-item">
                <Link to="/completed-orders" className="link-dark">
                  <i
                    className="fa fa-calendar-check-o"
                    style={{
                      fontSize: "23px",
                      color: "#030f27",
                      marginTop: "7px",
                    }}
                  />
                  <span className="d-none d-md-inline-block ms-1">
                    Completed Orders
                  </span>
                </Link>
              </li>
              <li className="list-inline-item">
                <Link to="/cancelled-orders" className="link-dark">
                  <i
                    className="fa fa-calendar-times-o"
                    style={{
                      fontSize: "23px",
                      color: "#030f27",
                      marginTop: "7px",
                    }}
                  />
                  <span className="d-none d-md-inline-block ms-1">
                    Cancelled Orders
                  </span>
                </Link>
              </li> */}
              <li className="list-inline-item">
                <div className="dropdown">
                  <button
                    className="btn btn-secondary dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    style={{
                      background: "none",
                      border: "none",
                      marginTop: "6px",
                      color: "#030f27",
                    }}
                  >
                    <i
                      className="fa fa-user-circle me-2"
                      aria-hidden="true"
                      style={{ fontSize: "20px", color: "#030f27" }}
                    ></i>
                    {getfName[0]}
                  </button>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li>
                      <span className="dropdown-item">{User}</span>
                    </li>
                    <li>
                      <Link
                        to="/quick-order"
                        className="link-dark dropdown-item"
                      >
                        Quick Order
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/bulk-order"
                        className="link-dark dropdown-item"
                      >
                        Bulk Order
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/"
                        onClick={handleLogout}
                        className="link-dark dropdown-item"
                      >
                        {/* <i
                    className="fa fa-sign-out"
                    style={{ fontSize: "25px", color: "#030f27" }}
                  /> */}
                        {/* <span className="d-none d-md-inline-block ms-1">Sign-out</span> */}
                        Sign-out
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </nav>
      </header>
      <Sidebar show={shows} onHide={closeSidebar} />
    </>
  );
};
export default Navbar;
