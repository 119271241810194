import React, { FunctionComponent as Component, useState } from "react";
import "../../assets/css/cart-card.css";
import { CardInterface } from "./category-interface";
// import { CONSTANT } from "../../utils/constant";
import { DEFAULT_API_CONFIG } from "../../services/config/api-config";
import noimage from "./../../assets/img/nia.jpg";
import { useDispatch } from "react-redux";
import { DeleteCartAPI, cartAPI, PostCartAPI } from "../../store/screens/cart";
import { PostWishlistAPI, wishlistAPI } from "../../store/screens/wishlist";
import { Link, useNavigate, useParams } from "react-router-dom";
import { PlaceOrderAPI } from "../../store/screens/order";
import ToastNotification from "../toast-notification";

export const CartCard: Component<CardInterface> = (props: any) => {
  const {
    name,
    image,
    weight,
    net_weight,
    bom_factory_code,
    totalWeight,
    order,
    remark,
    setRev,
    wastage,
    barcodeimage,
    level_2_category,
    market_design_name,
  } = props;
  const dispatch = useDispatch();
  console.log(name);
  console.log(order);
  // const { cartCount, setCartCount } = useNavbar();
  // const {
  //   handleReOrderQuantityIncreased,
  //   reOrderQtyIncrease,
  //   handleReOrderQuantityDecreased,
  //   reOrderQtyDecrease,
  //   handleReOrderInput,
  // } = useCart();
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [isWastageFieldEditable, setWastageFieldEditable] = useState(false);
  let [wastageContent, setWastageContent] = useState("");
  const [inputValue, setInputValue] = useState("");
  const Param = useParams();

  let user = localStorage.getItem("user");
  let users = user ? JSON.parse(user) : null;
  let param = {
    user: users,
  };

  let purity: any = localStorage.getItem("localPurity");
  const getCustomerName: any = localStorage.getItem("customer-name");
  const getSelectedColor: any = localStorage.getItem("selectedColor");

  // let user = localStorage.getItem("user");
  // let users = user ? JSON.parse(user) : null;

  const handleRemoveItem = async (name: any) => {
    // console.log("qty delete all")
    let productCodes: string[];
    let params = {
      user: users,
      item_code: name,
    };

    let param = {
      user: users,
    };
    await dispatch(DeleteCartAPI(params));
    await dispatch(cartAPI(param));
    if (localStorage.getItem("product-codes")) {
      productCodes = JSON.parse(localStorage.getItem("product-codes") || "");
      console.log(productCodes);
      // productCodes.filter((item)=> item !== name).map((title)=> )
      localStorage.setItem(
        "product-codes",
        JSON.stringify(productCodes.filter((item) => item !== name))
      );
    }
    setRev(true);
  };

  const handleRemove = async (name: any, size: any) => {
    let productCodes: string[];
    let param = {
      user: users,
    };
    let paramss = {
      user: users,
      item_code: name,
      size: size,
    };
    // console.log("qty delete");
    await dispatch(DeleteCartAPI(paramss));
    if (localStorage.getItem("product-codes")) {
      productCodes = JSON.parse(localStorage.getItem("product-codes") || "");
      console.log(productCodes);
      // productCodes.filter((item)=> item !== name).map((title)=> )
      localStorage.setItem(
        "product-codes",
        JSON.stringify(productCodes.filter((item) => item !== name))
      );
    }
    setShow1(true);
    await dispatch(cartAPI(param));
  };

  const handleWhishlist = async (name: any) => {
    let params = {
      user: users,
      item_code: name,
    };
    let param = {
      user: users,
    };

    await dispatch(PostWishlistAPI(params));
    setShow2(true);
    await dispatch(wishlistAPI(param));
    await dispatch(DeleteCartAPI(params));
    dispatch(cartAPI(param));
  };

  const handleSave = async (size: any, qty: any) => {
    console.log(size);
    console.log(qty);
  };

  const handleInput = async (value: any, size: any, colour: any) => {
    setInputValue(value);

    let params: any;
    params = {
      user: users,
      cust_name: getCustomerName,
      item_code: name,
      purity: purity,
      // colour: productColor,
      colour: getSelectedColor,
      remark: remark,
      wastage: wastage,
      qty_size_list: [
        { qty: `${value}`, size: `${size}`, colour: `${colour}` },
      ],
    };
    console.log("input qty ", params);
    await dispatch(PostCartAPI(params));
    await dispatch(cartAPI(param));
  };

  const handleEditWastage = () => {
    setWastageFieldEditable(true);
  };

  const handleWastageContent = (event: any) => {
    const newValue = event.target.innerText;
    setWastageContent(newValue);
  };

  const handleUpdateWastage = async () => {
    console.log("wastage content", wastageContent);
    let params: any;
    params = {
      user: users,
      cust_name: getCustomerName,
      item_code: name,
      purity: purity,
      // colour: productColor,
      colour: getSelectedColor,
      remark: remark,
      wastage: wastageContent,
      qty_size_list: [...order],
    };
    // console.log("wastage content ", params);
    await dispatch(PostCartAPI(params));
    await dispatch(cartAPI(param));
    setWastageFieldEditable(false);
    setWastageContent("");
  };

  const handleQuantityIncreased = async (qty: any, size: any, colour: any) => {
    let params: any;
    params = {
      user: users,
      cust_name: getCustomerName,
      item_code: name,
      purity: purity,
      // colour: productColor,
      colour: getSelectedColor,
      remark: remark,
      wastage: wastage,
      qty_size_list: [
        { qty: `${qty + 1}`, size: `${size}`, colour: `${colour}` },
      ],
    };
    console.log("qty ", params);
    await dispatch(PostCartAPI(params));
    await dispatch(cartAPI(param));
  };

  const handleQuantityDecreased = async (
    name: any,
    qty: any,
    size: any,
    colour: any
  ) => {
    if (qty - 1 !== 0) {
      // console.log("qty negate")
      let params: any;
      params = {
        user: users,
        cust_name: getCustomerName,
        item_code: name,
        purity: purity,
        // colour: productColor,
        colour: getSelectedColor,
        remark: remark,
        wastage: wastage,
        qty_size_list: [
          { qty: `${qty - 1}`, size: `${size}`, colour: `${colour}` },
        ],
      };
      console.log("qty ", params);
      await dispatch(PostCartAPI(params));
      await dispatch(cartAPI(param));
    } else {
      // console.log("qty remove")
      handleRemove(name, size);
    }
  };
  console.log(remark, "remark");
  return (
    <>
      {/* {show1 ? (
        <ToastNotification
          setShow={setShow1}
          show={show1}
          content="Product Size Removed!"
        />
      ) : (
        ""
      )}
      {show2 ? (
        <ToastNotification
          setShow={setShow2}
          show={show2}
          content="Product Moved To Wishlist!"
        />
      ) : (
        ""
      )} */}
      <ToastNotification
        setShow={setShow1}
        show={show1}
        content="Product Size Removed!"
      />
      <ToastNotification
        setShow={setShow2}
        show={show2}
        content="Product Moved To Wishlist!"
      />
      <div className="row card-list border-end cart_card">
        <div className="col-md-7 border-start border-bottom d-flex flex-column justify-content-center text-center border-end">
          <div className="row align-items-center">
            <div className="col-4 ">
              <div className="img-wrap text-center">
                {/* <Link to={"/product-detail/" + name}> */}
                <Link
                  to={
                    "/product-detail/" +
                    Param.categoryId +
                    "/" +
                    Param.subCategoryId +
                    "/" +
                    name
                  }
                >
                  <img
                    src={image ? DEFAULT_API_CONFIG.url + image : noimage}
                    alt=""
                    width={150}
                    className="img-fluid"
                  />
                </Link>
              </div>
            </div>
            <div className="col-4 p-details text-start ">
              <div className="item-block">
                {/* <Link to={"/product-detail/" + name}> */}
                <img
                  src={barcodeimage}
                  alt="barcode image"
                  width={100}
                  height={50}
                />
                <Link
                  to={
                    "/product-detail/" +
                    Param.categoryId +
                    "/" +
                    Param.subCategoryId +
                    "/" +
                    name
                  }
                >
                  <p className="mb-0">Product code:- {name}</p>
                  <p className="fw-bold bom_code_fs">
                    BOM Factory Code:- {bom_factory_code}
                  </p>
                  {level_2_category === "BALL CHAINS" && (
                    <p>Market Design Name:- {market_design_name}</p>
                  )}
                </Link>
                <p className="mb-0">Weight:- {parseInt(weight)} gm</p>
                {(level_2_category === "MANGALSUTRA" ||
                  level_2_category === "IMP PREMIUM") && (
                  <p className="mb-2">Net Wt. {parseInt(net_weight)}gm</p>
                )}
                {/* {cartCount !== 0 && ( */}
                <Link
                  className="wishlist"
                  to=""
                  onClick={() => handleWhishlist(name)}
                >
                  Move to wishlist
                </Link>
                {/* )} */}
              </div>
            </div>
            <div className="col-4">
              {/* <h5 className="mt-4">Remark</h5> */}
              {/* <p className="text-dark mt-2" style={{ fontSize: "12px" }}>
                Wastage:-{" "}
                {isWastageFieldEditable ? (
                  <span
                    contentEditable
                    className="ms-2"
                    style={{
                      border: "1px solid black",
                      display: "inline-block",
                      width: "50px",
                    }}
                    onBlur={(e: any) => handleWastageContent(e)}
                  >
                    {wastageContent}
                  </span>
                ) : (
                  <span className="p-2">{wastage}</span>
                )}
              </p>
              {isWastageFieldEditable ? (
                <button
                  style={{
                    fontSize: "12px",
                    color: "blue",
                    marginTop: "12px",
                    backgroundColor: "white",
                    border: "none",
                  }}
                  onClick={handleUpdateWastage}
                >
                  Update Wastage
                </button>
              ) : (
                <button
                  style={{
                    fontSize: "12px",
                    color: "blue",
                    marginTop: "12px",
                    backgroundColor: "white",
                    border: "none",
                  }}
                  onClick={handleEditWastage}
                >
                  Edit Wastage
                </button>
              )} */}

              <textarea
                className="remark-cart text-center mt-2"
                id=""
                style={{ wordWrap: "break-word", color: "#000000" }}
                readOnly={true}
              >
                {remark}
              </textarea>
            </div>
          </div>
        </div>
        <div className="col-md-3 p-0 ">
          <table>
            <tr>
              <th>Color</th>
              <th>Size(Inch)</th>
              <th>Qty</th>
              <th>Weight(gm)</th>
              {/* <th>Description</th> */}
              {/* <th>Edit</th> */}
              <th></th>
            </tr>

            {order.length > 0 &&
              order.map((data: any, index: any) => {
                return (
                  <tr key={index}>
                    <td>{data.colour}</td>
                    {/* <td contentEditable="true">{data.size}</td>
                    <td contentEditable="true">{data.qty}</td> */}
                    {/* <td contentEditable="true">{data.qty}</td>  */}
                    <td>{data.size}</td>
                    <td>
                      <div className="d-flex">
                        {/* {cartCount !== 0 && ( */}
                        <button
                          type="button"
                          className="qty_update_btn ms-2"
                          onClick={() =>
                            handleQuantityDecreased(
                              name,
                              data.qty,
                              data.size,
                              data.colour
                            )
                          }
                        >
                          <i className="fa fa-minus" aria-hidden="true"></i>
                        </button>
                        {/* )} */}
                        {/* {cartCount === 0 && (
                          <button
                            type="button"
                            className="qty_update_btn ms-2"
                            onClick={() =>
                              handleReOrderQuantityDecreased(
                                name,
                                data.qty,
                                data.size,
                                data.colour
                              )
                            }
                          >
                            <i className="fa fa-minus" aria-hidden="true"></i>
                          </button>
                        )} */}

                        {/* {cartCount !== 0 && ( */}
                        <input
                          type="text"
                          value={data.qty}
                          style={{ width: "35px", height: "25px" }}
                          className="m-1 qty_input"
                          onChange={(e) =>
                            handleInput(e.target.value, data.size, data.colour)
                          }
                        />
                        {/* )} */}
                        {/* {cartCount === 0 && (
                          <input
                            type="text"
                            value={
                              reOrderQtyIncrease !== 0
                                ? reOrderQtyIncrease
                                : data.qty
                            }
                            style={{ width: "35px", height: "25px" }}
                            className="m-1 qty_input"
                            onChange={(e) =>
                              handleReOrderInput(
                                e.target.value,
                                data.size,
                                data.colour
                              )
                            }
                          />
                        )} */}
                        {/* {cartCount !== 0 && ( */}
                        <button
                          type="button"
                          className="qty_update_btn me-2"
                          onClick={() =>
                            handleQuantityIncreased(
                              data.qty,
                              data.size,
                              data.colour
                            )
                          }
                        >
                          <i className="fa fa-plus" aria-hidden="true"></i>
                        </button>
                        {/* )} */}
                        {/* {cartCount === 0 && ( */}
                        {/* <button
                          type="button"
                          className="qty_update_btn me-2"
                          onClick={() =>
                            handleReOrderQuantityIncreased(
                              data.qty,
                              data.size,
                              data.colour
                            )
                          }
                        >
                          <i className="fa fa-plus" aria-hidden="true"></i>
                        </button> */}
                        {/* )} */}
                      </div>
                    </td>
                    <td className="text-end">{data.weight.toFixed(2)}gm</td>
                    {/* <td className="text-center">{data?.remark}</td> */}
                    {/* <td>{(data.qty * weight).toFixed(2)}gm</td> */}
                    {/* <td><a href=""><i className="fa fa-pencil-square-o" aria-hidden="true" style={{fontSize:'16px',marginLeft:'4px',marginTop:'4px'}}></i></a></td> */}
                    <td>
                      {" "}
                      <button
                        type="button"
                        className="btn-close"
                        // onClick={handleCheck}
                        onClick={() => handleRemove(name, data.size)}
                      />
                    </td>
                  </tr>
                );
              })}
            <tr>
              <td colSpan={2}>Total Weight:</td>
              <td colSpan={3} className="text-end">
                {totalWeight.toFixed(2)} gm
              </td>
            </tr>
          </table>
        </div>
        <div className="col-md-2 d-flex align-items-center justify-content-center btn-cart-cross border-bottom border-end">
          <button
            type="button"
            className="btn-close main-btn-cross"
            onClick={() => handleRemoveItem(name)}
          />
        </div>
      </div>
    </>
  );
};
