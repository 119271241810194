export enum OrderAPIMethods {
  orderList = "/api/method/digitalcatalog_api_erpnext.api.order.get_order",
  placeOrderList = "/api/method/digitalcatalog_api_erpnext.api.order.place_order",
  orderDetailList = "api/method/digitalcatalog_api_erpnext.api.order.get_order_detail",
  // dispatchOrder = '/api/method/digitalcatalog_api_erpnext.api.order.dispatch_order'
  dispatchOrder = "/api/method/digitalcatalog_api_erpnext.api.order.change_status",
  cancelOrder = "/api/method/digitalcatalog_api_erpnext.api.sales_order_cancelled.sales_order_cancel",
  chamgeItemStatus = "api/method/digitalcatalog_api_erpnext.digitalcatalog_api_erpnext.customizations.sales_order.api.soid_status.update_soid_completed_status",
  userAccessDispatch = "/api/method/digitalcatalog_api_erpnext.digitalcatalog_api_erpnext.customizations.sales_order.api.soid_status.ready_to_dispatch_permisson",
  deleteItem = "/api/method/digitalcatalog_api_erpnext.digitalcatalog_api_erpnext.customizations.sales_order.api.soid_status.delete_soid_and_child_item",
  userAccessDelete = "api/method/digitalcatalog_api_erpnext.digitalcatalog_api_erpnext.customizations.sales_order.api.soid_status.delete_order_permisson",
}
