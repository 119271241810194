import { useState } from "react";
import { Link } from "react-router-dom";
import "../../assets/css/order-list.css";
import img from "../../assets/img/order-list.png";
import Breadcrumb from "../../components/breadcrumb";
import { Norecord } from "../../components/norecord";
import ToastNotification from "../../components/toast-notification";
import { useOrderList } from "./components/order-list-hooks";
const OrderList = () => {
  const {
    orderlist,
    handleSelectOrder,
    handleCancelOrders,
    selectedOrders,
    notification,
    showNotification,
    setShowNotification,
  } = useOrderList();
  const [dispatchWeight, setdispatchWeight] = useState("");

  const changeDispatchWeight = (e: any) => {
    setdispatchWeight(e.target.value);
  };

  return (
    <>
      <ToastNotification
        setShow={setShowNotification}
        show={showNotification}
        content={notification}
      />
      <div className="breadcrumb-sec">
        <div className="container-lg">
          <div className="row">
            <div className="col-12">
              <Breadcrumb
                breadcrumbs={[
                  { to: "/", name: "Home" },
                  { to: "/order-list", name: "Order List" },
                ]}
              />
            </div>
          </div>
        </div>
      </div>
      {orderlist.orderlist.length > 0 ? (
        <div className="container-lg pt-5">
          <div className="order-list-cont">
            <div className="row pb-3 ">
              <div className="row my-2 w-100 p-0">
                <div className="offset-4 col-4 text-center">
                  <h2>Order List</h2>
                </div>
                <div className="col-4 text-end p-0">
                  <button
                    className="btn btn-secondary"
                    onClick={handleCancelOrders}
                  >
                    Cancel
                  </button>
                </div>
              </div>
              <div className="">
                <table>
                  <tr style={{ fontSize: "16px !important" }}>
                    <th></th>
                    <th>Order Date</th>
                    <th>Order Id</th>
                    <th>Customer Name</th>
                    <th>Total Ordered Weight</th>
                    <th>Due Date</th>
                    <th>Status</th>
                  </tr>

                  {orderlist.orderlist.map((order: any, index: any) => {
                    return (
                      <tr key={index + order?.name}>
                        <td className="p-0 ">
                          <div className="p-2 ">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value=""
                              id="flexCheckDefault"
                              checked={
                                selectedOrders?.length > 0
                                  ? selectedOrders?.some(
                                      (item: any) => item === order?.name
                                    )
                                  : false
                              }
                              onChange={() => handleSelectOrder(order?.name)}
                            />
                          </div>
                        </td>
                        <td>
                          <strong>{order.transaction_date}</strong>
                        </td>
                        <td>
                          <Link
                            to={"/order-detail/" + order.name}
                            className="order-link"
                          >
                            <b>{order.name}</b>
                          </Link>
                        </td>
                        <td className="">
                          <strong>{order.cust_name} </strong>
                        </td>

                        <td className="">
                          <strong>{order.total_weight.toFixed(2)} gm</strong>
                        </td>
                        <td className="">
                          <strong>{order.delivery_date} </strong>
                        </td>
                        <td>
                          <strong>{order.status}</strong>
                        </td>

                        {/* <td>
                          <input
                            type="text"
                            name="dispatch_weight"
                            id="dispatch_weight"
                            // value={dispatchWeight}
                            onChange={changeDispatchWeight}
                          />
                        </td>
                        <td>
                          <button className=" ps-5 pe-5 " onClick={()=>handleDispatch(order.name,dispatchWeight)}> Dispatch</button>
                        </td> */}
                      </tr>
                    );
                  })}
                </table>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Norecord
          heading="Order-List"
          img={img}
          content="Sorry for disappointing you! We’re unable to find any relevant data"
        />
      )}
    </>
  );
};
export default OrderList;
