import React, { useState } from "react";
import BulkDropdownInput from "./BulkDropdown";
import useBulkListOrder from "../../pages/bulk-order/components/bulk-item-code-hook";

const CustomeMarketOrder = ({
  formData,
  addCustomMarketOrderRow,
  handleChangeArray,
  deleteCustomMarketOrderRow,
  errorMsg,
}: any) => {
  const { refCodesList }: any = useBulkListOrder();
  const [inputValues, setInputValues] = useState<string[]>([]);
  const handleInputValueChange = (value: string, index: number) => {
    const newInputValues = [...inputValues];
    newInputValues[index] = value;
    setInputValues(newInputValues);
  };

  return (
    <>
      <div className="row mt-4 mb-2">
        <div className="col-md-9">
          <p className="fs-14">Custom Market Order Details</p>
        </div>
        <div className="col-md-3 d-flex justify-content-end">
          <button
            className="btn-save-bulk-order"
            onClick={addCustomMarketOrderRow}
          >
            Add More
          </button>
        </div>
      </div>
      <div className="table-container">
        <div className="table-wrapper">
          <table className="table ">
            <thead className="fixed-header">
              <tr>
                <th>
                  Design Name<span className="text-danger">*</span>
                </th>
                <th>
                  Description<span className="text-danger">*</span>
                </th>
                <th>
                  Inch Size<span className="text-danger">*</span>
                </th>
                <th>
                  Inch Qty<span className="text-danger">*</span>
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody style={{ position: "relative" }}>
              {formData?.customMarketOrderDetails?.map(
                (row: any, index: any) => (
                  <tr key={index}>
                    <td
                      className="search-input-bulk-order w-25"
                      style={{ position: "relative" }}
                    >
                      <BulkDropdownInput
                        dropdownData={refCodesList?.map(
                          (ele: any) => ele?.reference_code
                        )}
                        inputValue={inputValues[index] || ""}
                        setInputValue={(value: string) =>
                          handleInputValueChange(value, index)
                        }
                        disabled={""}
                        name={`item_code${index}`}
                        onChange={(e: any) =>
                          handleChangeArray(
                            { target: { value: e } },
                            index,
                            "item_code"
                          )
                        }
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        name={`remark${index}`}
                        className="w-100 px-1"
                        value={row.remark}
                        onChange={(e) => handleChangeArray(e, index, "remark")}
                      />
                    </td>
                    <td>
                      {row.qty.map((item: any, subIndex: number) => (
                        <React.Fragment key={subIndex}>
                          <input
                            type="number"
                            name={`size${index}.${subIndex}`}
                            value={item.size}
                            className="w-100 input-type-number px-1"
                            onChange={(e) =>
                              handleChangeArray(e, index, "size", subIndex)
                            }
                          />
                        </React.Fragment>
                      ))}
                    </td>
                    <td>
                      {row.qty.map((item: any, subIndex: number) => (
                        <React.Fragment key={subIndex}>
                          <input
                            type="number"
                            name={`qty${index}.${subIndex}`}
                            value={item.qty}
                            className="w-100 input-type-number px-1"
                            onChange={(e) =>
                              handleChangeArray(e, index, "qty", subIndex)
                            }
                          />
                        </React.Fragment>
                      ))}
                    </td>

                    <td className="text-end">
                      <button
                        className="btn-delete-bulk-order"
                        onClick={() => deleteCustomMarketOrderRow(index)}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                )
              )}

              {errorMsg && (
                <tr>
                  <td colSpan={5} className="w-100 text-danger text-center">
                    {errorMsg}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default CustomeMarketOrder;
