import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import api from "../../services/api/cart-api";
import { handleResponse } from "../../services/config/handle-response";
import { CONSTANT } from "../../utils/constant";

interface RepoCartState {
  cart: any;
  error: any;
}

const initialState: RepoCartState = {
  cart: [],
  error: "",
};

const cartScreen = createSlice({
  name: "cart",
  initialState,
  reducers: {
    getCartSuccess(state, action: PayloadAction<RepoCartState>) {
      // localStorage.setItem('user', JSON.stringify(action.payload));
      state.cart = action.payload;
      state.error = "";
    },
    cartFailed(state) {
      state.cart = [];
      state.error = "Error or Occured";
    },
  },
});

const { getCartSuccess, cartFailed } = cartScreen.actions;

export const cartAPI =
  (request: any): any =>
  async (dispatch: any) => {
    try {
      const response = await api.getCartListAPI(request);
      // console.log(response);
      const result = dispatch(handleResponse(response));
      // console.log(result);
      if (result) {
        dispatch(getCartSuccess(result));
      } else {
        dispatch(cartFailed());
      }
    } catch (err) {}
  };

export const PostCartAPI =
  (request: any): any =>
  async (dispatch: any) => {
    console.log("POST CART API REQUEST", request);
    try {
      const response = await api.postCartListAPI(request);
      // console.log(response)
      const result = dispatch(handleResponse(response));
      // console.log(result)
    } catch (err) {}
  };

export const DeleteCartAPI =
  (request: any): any =>
  async (dispatch: any) => {
    try {
      const response = await api.deleteCartListAPI(request);
      const result = dispatch(handleResponse(response));
    } catch (err) {}
  };

export const RemoveAllItemsAPI =
  (request: any): any =>
  async (dispatch: any) => {
    try {
      const response = await api.removeAllCartListAPI(request);
      const result = dispatch(handleResponse(response));
    } catch (err) {}
  };

export const GetPurityListAPI = async () => {
  try {
    const response = await api.fetchPurity();
    return response?.data?.data;
  } catch (err) {}
};

export const updatePurity = async () => {};

export default cartScreen.reducer;
