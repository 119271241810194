import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { postCancelOrder } from "../../../services/api/cancel-order-api";
import { RootState } from "../../../store/root-reducer";
import { orderAPI } from "../../../store/screens/order";

export const useOrderList = () => {
  const dispatch = useDispatch();
  let orderlist = useSelector((state: RootState) => state.orders);
  const [selectedOrders, setSelectedOrders] = useState<string[]>([]);
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [notification, setNotification] = useState<string>("");

  // Toggle selection of an order
  const handleSelectOrder = (orderId: string) => {
    setSelectedOrders((prevSelected) =>
      prevSelected.includes(orderId)
        ? prevSelected.filter((id) => id !== orderId)
        : [...prevSelected, orderId]
    );
  };

  // Handle Cancel action for selected orders
  const handleCancelOrders = async () => {
    const params = {
      id: selectedOrders,
    };
    if (selectedOrders.length === 0) {
      setShowNotification(true);
      setNotification("Please select at least one order to cancel.");
      return;
    } else {
      const cancelOrder: any = await postCancelOrder(params);
      console.log(cancelOrder, "data111");
      if (cancelOrder?.status === 200) {
        let user = localStorage.getItem("user");
        let users = user ? JSON.parse(user) : null;
        let params = {
          user: users,
        };
        if (cancelOrder?.data?.message?.length === 0) {
          dispatch(orderAPI(params));
          setShowNotification(true);
          setNotification("Order cancelled successfully!");
        } else {
          const orderId = cancelOrder?.data?.message?.map((order: any) =>
            Object.keys(order)
          );
          dispatch(orderAPI(params));
          setShowNotification(true);
          setNotification(
            `Order ID ${orderId?.map((item: any) => item)} cannot be cancelled!`
          );
        }
      } else {
        setNotification("Something went wrong!");
      }
    }
    setSelectedOrders([]);
  };

  // useEffect(()=>
  // {
  // },[])

  // Below function is to change the order status from 'To Deliver and Bill' to 'Completed'
  // const handleDispatch = async(orderName:any, weight:any)=>
  // {
  //   let user = localStorage.getItem("user");
  //   let users = user ? JSON.parse(user) : null;

  //   // console.log("dispatch",orderName);
  //   // console.log("dispatch",weight);
  //   let userParam = {
  //     user: users,
  //   }
  //   let params = {
  //     order_id:orderName,
  //   }
  //   dispatch(dispatchOrderAPI(params));
  //   // dispatch(orderAPI(userParam));
  //   setTimeout(()=>
  //   {
  //     dispatch(orderAPI(userParam));
  //   },2000)
  // }

  useEffect(() => {
    let user = localStorage.getItem("user");
    let users = user ? JSON.parse(user) : null;
    let params = {
      user: users,
    };
    dispatch(orderAPI(params));
  }, []);

  useEffect(() => {
    orderlist = orderlist;
  }, [orderlist]);

  return {
    orderlist,
    handleSelectOrder,
    handleCancelOrders,
    selectedOrders,
    notification,
    showNotification,
    setShowNotification,
    // handleDispatch
  };
};
