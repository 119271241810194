import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useParams,
  useNavigate,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import { RootState } from "../../../store/root-reducer";
import { allproductsAPI, productsAPI } from "../../../store/screens/products";
import { cartAPI } from "../../../store/screens/cart";

type CheckedType = {
  weight_range: never[];
  L2: never[];
  L3: never[];
  Color: never[];
  item_code: string;
};
export const useProducts = () => {
  const dispatch = useDispatch();
  const [checked, setChecked] = useState<CheckedType>({
    weight_range: [],
    L2: [],
    L3: [],
    Color: [],
    item_code: " ",
  });
  const productData = useSelector((state: RootState) => state.products);
  // const filters = useSelector((state:RootState)=> state.filters);
  const [products, setProducts] = useState<any>([]);
  const [filtersArr, setFiltersArr] = useState([]);
  let [savePrevAndNextData, setSavePrevAndNextData] = useState<any>([]);
  let [arrayOfCodes, setarrayOfCodes] = useState<string[]>([]);
  const [totalCount, setTotalRecord] = useState(1);
  const [curretPage, setCurrentPage] = useState(1);
  const [pageNumberLimit, setpageNumberLimit] = useState(5);
  const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5);
  const [minPageNumberLimit, setminPageNumberLimit] = useState(0);
  const [startPageNumber, setstartPageNumber] = useState(0);
  const [endPageNumber, setendPageNumber] = useState(5);
  const [sortBySeq, setSortBySeq] = useState("creation");
  const [sortByDesc, setSortByDesc] = useState(true);
  const cart = useSelector((state: RootState) => state.carts);
  const [carts, setCarts] = useState<any>([]);
  const [selectedPurityValue, setSelectedPurityValue] = useState<any>("");
  const [customerName, setcustomerName] = useState("");
  let navigate = useNavigate();
  let currentPathname: any = window.location.pathname;
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  let Id = params.subCategoryId;
  const [selectedFilter, setSelectedFilter] = useState({});

  const getUserSelectedFilters: any = localStorage.getItem("filters");
  const parsedFilters = JSON.parse(getUserSelectedFilters);

  useEffect(() => {
    let user = localStorage.getItem("user");
    let users = user ? JSON.parse(user) : null;
    let usertoken = {
      user: users,
    };
    dispatch(cartAPI(usertoken));
  }, []);

  const createArrayOfProductCodes = (data: string[]) => {
    console.log("array fucntion data", data);
    data?.map((item: any) => {
      item.orders?.map((item2: any) => {
        if (!arrayOfCodes.includes(item2.item_code)) {
          setarrayOfCodes((arrayOfCodes = [...arrayOfCodes, item2.item_code]));
        } else {
          setarrayOfCodes((arrayOfCodes = [...arrayOfCodes]));
        }
      });
    });
  };

  useEffect(() => {
    let pageNo: any = searchParams.get("page");
    let pg = parseInt(pageNo, 10);
    setCurrentPage(pg);
    if (pg === 1) {
      let filter = "";
      let dataLimit = 20;
      let start = pg !== 1 ? (pg - 1) * dataLimit : 0;
      let end = start - dataLimit + 1;
      if (currentPathname === "/new-arrival") {
        dispatch(
          productsAPI(
            params.subCategoryId,
            params.categoryId,
            start,
            end,
            filter,
            sortBySeq,
            sortByDesc,
            "New Arrival"
          )
        );
      } else {
        dispatch(
          productsAPI(
            params.subCategoryId,
            params.categoryId,
            start,
            end,
            filter,
            sortBySeq,
            sortByDesc
          )
        );
      }
    } else {
      let filter = {};
      let dataLimit = 20;
      let start = pg !== 1 ? (pg - 1) * dataLimit : 0;
      let end = start - dataLimit + 1;
      if (!params.hasOwnProperty("productId")) {
        dispatch(
          productsAPI(
            params.subCategoryId,
            params.categoryId,
            start,
            end,
            filter,
            sortBySeq,
            sortByDesc
          )
        );
      }
    }
    if (pg > maxPageNumberLimit) {
      setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }

    createArrayOfProductCodes(cart.cart?.data);
  }, [params.categoryId, params.subCategoryId, sortBySeq, sortByDesc]);

  useEffect(() => {
    createArrayOfProductCodes(cart.cart?.data);
  }, [cart]);

  useEffect(() => {
    const productDataValue =
      productData?.products?.product?.length > 0 &&
      [...productData?.products?.product].sort((a: any, b: any) => {
        const modifiedDate_b: any = new Date(b.modified);
        const modifiedDate_a: any = new Date(a.modified);
        return modifiedDate_b - modifiedDate_a;
      });
    setProducts(productDataValue);

    setTotalRecord(productData.products.total_count);
    setFiltersArr(productData.filtersArr);
  }, [productData, curretPage, checked]);

  const pageCondition = (productPage: any, start: any, end: any) => {
    console.log("product page", productPage);
    if (productPage?.hasOwnProperty("categoryId")) {
      console.log("in cuban");
      if (Object.keys(selectedFilter).length > 0) {
        dispatch(
          productsAPI(
            params.subCategoryId,
            params.categoryId,
            start,
            end,
            selectedFilter,
            sortBySeq,
            sortByDesc
          )
        );
      } else {
        dispatch(
          productsAPI(
            params.subCategoryId,
            params.categoryId,
            start,
            end,
            "",
            sortBySeq,
            sortByDesc
          )
        );
      }
    } else if (currentPathname?.includes("/new-arrival")) {
      if (Object.keys(selectedFilter).length > 0) {
        dispatch(
          productsAPI(
            params.subCategoryId,
            params.categoryId,
            start,
            end,
            selectedFilter,
            sortBySeq,
            sortByDesc,
            "New Arrival"
          )
        );
      } else {
        dispatch(
          productsAPI(
            params.subCategoryId,
            params.categoryId,
            start,
            end,
            "",
            sortBySeq,
            sortByDesc,
            "New Arrival"
          )
        );
      }
    } else {
      if (Object.keys(selectedFilter).length > 0 && checked.L2.length > 0) {
        dispatch(
          allproductsAPI(params.categoryId, checked.L2, selectedFilter, start)
        );
      } else if (Object.keys(selectedFilter).length > 0) {
        dispatch(allproductsAPI(params.categoryId, "", selectedFilter, start));
      } else if (checked.L2.length > 0) {
        dispatch(allproductsAPI(params.categoryId, checked.L2, "", start));
      } else {
        dispatch(allproductsAPI(params.categoryId, "", "", start));
      }
    }
  };
  const handleName = (name: string) => {
    setcustomerName(name);
    console.log(customerName);
  };

  async function paginate(pageNumber: any, productPage?: any) {
    console.log("params page", pageNumber);

    let pageNo: any = searchParams.get("page");
    let pg = parseInt(pageNo, 10);
    if (pageNumber === 1) {
      if (currentPathname?.includes("/new-arrival")) {
        navigate(`/new-arrival?page=1`);
      } else {
        navigate(
          `/products/${params.categoryId}/${params.subCategoryId}?page=1`
        );
      }
    } else {
      if (currentPathname?.includes("/new-arrival")) {
        navigate(`/new-arrival?page=${pageNumber}`);
      } else {
        navigate(
          `/products/${params.categoryId}/${params.subCategoryId}?page=${pageNumber}`
        );
      }
    }

    setCurrentPage(pageNumber);
    let dataLimit = 20;
    let start = pageNumber !== 1 ? (pageNumber - 1) * dataLimit : 0;
    let end = start - dataLimit + 1;
    console.log("page condition", start, end);
    await pageCondition(productPage, start, end);
  }

  const handleSortBySeq = (seqValue: string) => {
    setSortBySeq(seqValue);
  };
  const handlesortByDesc = (seqValue: boolean) => {
    if (seqValue) {
      setSortByDesc(!sortByDesc);
    } else {
      setSortByDesc(!sortByDesc);
    }
  };

  const handleNextbtn = async (productPage?: any) => {
    console.log("params page", params);
    let pageNo: any = searchParams.get("page");
    // let pageNo: any = params.page;
    let pg = parseInt(pageNo, 10);
    if (currentPathname?.includes("/new-arrival")) {
      navigate(`/new-arrival?page=${pg - 1}`);
    } else {
      navigate(
        `/products/${params.categoryId}/${params.subCategoryId}?page=${pg + 1}`
      );
    }

    setCurrentPage(curretPage + 1);
    let dataLimit = 20;
    let start = curretPage !== 1 ? curretPage * dataLimit : 0;
    let end = start - dataLimit + 1;
    await pageCondition(productPage, start, end);
    console.log(curretPage);
    if (curretPage + 1 > maxPageNumberLimit) {
      setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
  };
  const handlePrevbtn = async (productPage?: any) => {
    // let pageNo: any = params.page;
    let pageNo: any = searchParams.get("page");
    let pg = parseInt(pageNo, 10);

    if (currentPathname?.includes("/new-arrival")) {
      navigate(`/new-arrival?page=${pg - 1}`);
    } else {
      navigate(
        `/products/${params.categoryId}/${params.subCategoryId}?page=${pg - 1}`
      );
    }

    setCurrentPage(curretPage - 1);
    let dataLimit = 20;
    let start = curretPage !== 1 ? (curretPage - 2) * dataLimit : 0;
    let end = start - dataLimit + 1;
    await pageCondition(productPage, start, end);
    if ((curretPage - 1) % pageNumberLimit == 0) {
      setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
    }
  };

  const handleFilter = (filters: any, productPage: any, searchValue?: any) => {
    setCurrentPage(1);
    setmaxPageNumberLimit(5);
    setminPageNumberLimit(0);
    let filter = "";
    let filterData;
    let url = window.location.href;

    if (filters) {
      if (
        filters.weight_range.length > 0 &&
        filters.L3.length > 0 &&
        filters.Color.length > 0
      ) {
        filter = `"item_group":["in",[${filters.L3}]],"weight_range":["in",[${filters.weight_range}]], "colour":["in",[${filters.Color}]]`;
      } else if (filters.L3.length > 0 && filters.weight_range.length > 0) {
        filter = `"item_group":["in",[${filters.L3}]], "weight_range":["in",[${filters.weight_range}]]`;
      } else if (filters.L3.length > 0 && filters.Color.length > 0) {
        filter = `"item_group":["in",[${filters.L3}]], "colour":["in",[${filters.Color}]]`;
      } else if (filters.weight_range.length > 0 && filters.Color.length > 0) {
        filter = `"weight_range":["in",[${filters.weight_range}]], "colour":["in",[${filters.Color}]]`;
      } else if (filters.weight_range.length > 0) {
        filter = `"weight_range":["in",[${filters.weight_range}]]`;
      } else if (filters.L3.length > 0) {
        filter = `"item_group":["in",[${filters.L3}]]`;
      } else if (filters.Color.length > 0) {
        filter = `"colour":["in",[${filters.Color}]]`;
      }

      console.log("filters in url", url);
      console.log("product page", productPage);
      filter = filter.replace(/,\s*$/, "");

      if (productPage) {
        if (currentPathname === "/new-arrival") {
          dispatch(
            productsAPI(
              params.subCategoryId,
              params.categoryId,
              0,
              12,
              filter,
              sortBySeq,
              sortByDesc,
              "New Arrival"
            )
          );
        } else {
          dispatch(
            productsAPI(
              params.subCategoryId,
              params.categoryId,
              0,
              12,
              filter,
              sortBySeq,
              sortByDesc
            )
          );
        }
        setSelectedFilter(filter);
        setCurrentPage(1);
      } else {
        dispatch(allproductsAPI(params.categoryId, filters.L2, filter, 0));
        setSelectedFilter(filter);
        setCurrentPage(1);
      }

      localStorage.setItem("filters", JSON.stringify(filters));
    } else {
      if (searchValue) {
        filter = `"item_code":"${searchValue}"`;
      }
      console.log(filter);
      if (currentPathname === "/new-arrival") {
        dispatch(
          productsAPI(
            params.subCategoryId,
            params.categoryId,
            0,
            12,
            filter,
            sortBySeq,
            sortByDesc,
            "New Arrival"
          )
        );
      } else {
        dispatch(
          productsAPI(
            params.subCategoryId,
            params.categoryId,
            0,
            12,
            filter,
            sortBySeq,
            sortByDesc
          )
        );
      }
      setSelectedFilter(filter);
      setCurrentPage(1);
    }
  };

  const handleCheckboxChange = async (
    e: any,
    index: number,
    type: string,
    productPage?: any
  ) => {
    // const handleCheckboxChange = async (e: any, index:number, type:string) => {
    console.log(productPage);
    let newArray: any = { ...checked };
    let getFilteredValues: any;

    let { value }: any = e.target;
    if (e.target.checked) {
      if (type === "weight_range")
        newArray[type] = [...checked[`${type}`], '"' + value + '"'];
      else if (type === "L3") {
        newArray[type] = [...checked[`${type}`], '"' + value + '"'];
      } else if (type === "L2") {
        // newArray[type] = [...checked[`${type}`],''+value+''];
        newArray[type] = ["" + value + ""];
        newArray["L3"] = [];
        newArray["weight_range"] = [];
      } else if (type === "Color") {
        newArray[type] = [...checked[`${type}`], '"' + value + '"'];
      }
      console.log("filters are tough", newArray);
    } else {
      let start = newArray[type].indexOf('"' + value + '"');
      let level2 = newArray[type].indexOf(value);
      console.log("second try", start);
      console.log("second try new arr", newArray[type]);
      if (start !== -1) {
        await newArray[type].splice(start, 1);
        console.log(checked);
        console.log("second try new arr inside start", newArray[type]);
      } else if (level2 !== -1) {
        await newArray[type].splice(level2, 1);
        newArray["L3"] = [];
        newArray["weight_range"] = [];
      }
    }
    // getFilteredValues = newArray
    handleFilter(newArray, productPage);
    // handleFilter(newArray);
    setChecked(newArray);
    // console.log(checked)
    console.log("new", newArray);
  };
  return {
    products,
    paginate,
    setCurrentPage,
    Id,
    handleCheckboxChange,
    handleSortBySeq,
    handlesortByDesc,
    handleFilter,
    totalCount,
    checked,
    curretPage,
    handleNextbtn,
    handlePrevbtn,
    maxPageNumberLimit,
    minPageNumberLimit,
    handleName,
    customerName,
    arrayOfCodes,
    filtersArr,
    savePrevAndNextData,
    sortBySeq,
    sortByDesc,
  };
};
