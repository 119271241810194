import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "../../assets/css/cart-card.css";
import img from "../../assets/img/empty_cart.png";
import Breadcrumb from "../../components/breadcrumb";
import { CartCard } from "../../components/cards/cart-card";
import { Norecord } from "../../components/norecord";
import ToastNotification from "../../components/toast-notification";
import { RootState } from "../../store/root-reducer";
import { useCart } from "./components/cart-hooks";
import ConfirmationModal from "./ConfirmationModal";
import { setPriority } from "os";

const Cart = () => {
  const {
    carts,
    finalWeight,
    handlePlace,
    deliveryNotification,
    setdeliveryNotification,
    handleReOrderPlace,
    reorderPurity,
    handleClearCart,
    showConfirmationModal,
    handleCloseConfirmationModal,
    handleShowConfirmationModal,
    confirmDelete,
    editableCustomerName,
    handleCustomerName,
    customerUpdateNotification,
    setcustomerUpdateNotification,
    handleCustomerNameUpdate,
    purity,
    selectedPurity,
    updatePurity,
    handlePurityUpdate,
  } = useCart();

  // const { orderData } = useOrderDetail();
  const orderData = useSelector((state: RootState) => state.orders.orderdetail);
  const reOrderDeliveryDate = orderData?.estimated_delivery_date;
  const reOrderTotalGrandWeight = orderData?.total_grand_weight;

  // const { cartCount }: any = useNavbar();
  const grandWeight = carts.total_grand_weight;

  const [rev, setRev] = useState(false);
  const [commoncomment, setcommoncomment] = useState("");
  const [viewPurity, setViewPurity] = useState("");

  let [date, setDate] = useState<any>("");
  let [estimatedDate, setestimatedDate] = useState<string>("");

  const [showUpdateCartButton, setShowUpdateCartButton] = useState(false);

  useEffect(() => {
    if (carts?.data) {
      setViewPurity(carts?.data[0]?.orders[0]?.purity);
    }
  }, [carts.data]);

  useEffect(() => {
    toShowDate();
  }, []);

  const handleDate = (e?: any) => {
    console.log("date", e.target.value);

    const date = e.target.value;
    const [year, month, day] = date.split("-");

    let fifteenDaysAheadDate;
    const getTodaysDate = new Date();
    fifteenDaysAheadDate = new Date(
      getTodaysDate.getFullYear(),
      getTodaysDate.getMonth(),
      getTodaysDate.getDate() + 15
    );

    console.log("date todays", fifteenDaysAheadDate);
    const enteredDate = new Date(year, month - 1, day);
    console.log(enteredDate);
    if (enteredDate >= fifteenDaysAheadDate) {
      const trimmed_date = [day, month, year].join("/");
      setestimatedDate(trimmed_date);
      setDate(e.target.value);
      // console.log(estimatedDate);
    } else {
      const today = new Date();
      today.setDate(today.getDate() + 15);
      const day = String(today.getDate()).padStart(2, "0");
      const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are zero-based
      const year = today.getFullYear();
      const trimmed_date = [day, month, year].join("/");
      const getFormattedDateToShow = toShowDate();
      setestimatedDate(trimmed_date);
      setDate(getFormattedDateToShow);
      setdeliveryNotification(true);
    }
  };

  const toShowDate = () => {
    const today = new Date();
    today.setDate(today.getDate() + 15);

    const day = String(today.getDate()).padStart(2, "0");
    const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = today.getFullYear();

    const formattedDate = `${year}-${month}-${day}`;

    console.log("date show", formattedDate);
    setDate(formattedDate);
    return formattedDate;
  };

  // console.log("date cart count", cartCount);

  // if(cartCount === 0 )
  // {
  //   localStorage.removeItem("category-name");
  // }
  const cartsData = carts?.data?.map((ele: any) =>
    ele?.orders?.map((ele: any) => ele)
  );
  const reOrdercartsData = carts?.data?.map((ele: any) => ele?.orders);
  // Declare variables outside
  let itemCode: any;
  let reorderPuritys: any;
  let orderDetails: any = [];
  let colour: any, qty: any, size: any, weight: any;
  // Accessing item_code if there is an extra array level
  if (
    Array.isArray(reOrdercartsData) &&
    reOrdercartsData.length > 0 &&
    Array.isArray(reOrdercartsData[0]) &&
    reOrdercartsData[0].length > 0
  ) {
    itemCode = reOrdercartsData[0][0].item_code;
    reorderPuritys = reOrdercartsData[0][0].purity;
    console.log("Item Code:", itemCode);

    // Accessing the order array
    orderDetails = reOrdercartsData[0][0].order;
    if (Array.isArray(orderDetails) && orderDetails.length > 0) {
      ({ colour, qty, size, weight } = orderDetails[0]);
    }
    console.log("Order Data:", orderDetails);
  } else {
    console.log("Data is not in expected format");
  }

  return (
    <>
      <ToastNotification
        setShow={setdeliveryNotification}
        show={deliveryNotification}
        content="Please Enter Valid Date - Delivery will take minimum of 15 days."
      />
      <div className="breadcrumb-sec">
        <div className="container-lg">
          <div className="row">
            <div className="col-6">
              <Breadcrumb
                breadcrumbs={[
                  { to: "/", name: "Home" },
                  { to: "/cart", name: "Cart" },
                ]}
              />
            </div>
          </div>
        </div>
      </div>

      {carts ? (
        carts.data ? (
          <div className="container mb-4">
            <div className="mt-4 w-100">
              <ToastNotification
                setShow={setRev}
                show={rev}
                content="Product Deleted!"
              />

              <ToastNotification
                setShow={setcustomerUpdateNotification}
                show={customerUpdateNotification}
                content="Updated Successfully"
              />
              {/* <div className="container p-0">
                <div className="row my-2 w-100 p-0 text-center">
                  <div className="offset-md-4 col-md-4 col-6 shopping-cart-heading">
                    <h2>My Shopping Cart</h2>
                  </div>
                  <div className="col-md-4 col-6 text-end p-lg-0">
                    <button
                      className="clear_cart_btn"
                      data-toggle="modal"
                      data-target="#confirmationModal"
                      onClick={handleShowConfirmationModal}
                    >
                      Clear Cart
                    </button>
                  </div>
                </div>
              </div> */}
              <div className="col-12 text-center mb-4 mt-4">
                <div className="shopping-cart-heading">
                  <h2>My Shopping Cart</h2>
                </div>
              </div>
              <div className="container border">
                <div className="row mb-2">
                  <div className="col-6 text-start mb-0 mt-2">
                    {/* <p className=" bold purity-txt">
                      Customer Name: {localStorage.getItem("customer-name")}
                    </p> */}
                    <span className=" bold purity-txt me-2">
                      Customer Name:
                    </span>
                    <input
                      type="text"
                      className="px-1"
                      value={
                        // localStorage.getItem("customer-name") ||
                        editableCustomerName
                      }
                      onChange={handleCustomerName}
                    />
                    <button
                      onClick={() =>
                        handleCustomerNameUpdate(
                          itemCode,
                          reorderPuritys,
                          qty,
                          size,
                          colour
                        )
                      }
                      style={{
                        color: "blue",
                        marginTop: "12px",
                        backgroundColor: "white",
                        border: "none",
                        fontSize: "13px",
                      }}
                      className="ms-2"
                    >
                      Update
                    </button>
                    <div>
                      <span className=" ">Order Purity : {viewPurity}</span>
                    </div>
                    <div>
                      <span className=" ">Update Purity :</span>
                      <select
                        className="bold purity-txt"
                        // value={selectedPurity}
                        onChange={updatePurity}
                        placeholder="text"
                      >
                        <option value="" disabled selected>
                          Select Purity
                        </option>
                        {purity?.map((item: any, index: any) => {
                          return (
                            <>
                              <option key={index} value={item?.name}>
                                {item?.name}
                              </option>
                            </>
                          );
                        })}
                      </select>
                      <button
                        onClick={() =>
                          handlePurityUpdate(
                            itemCode,
                            reorderPuritys,
                            qty,
                            size,
                            colour,
                            setViewPurity
                          )
                        }
                        style={{
                          color: "blue",

                          backgroundColor: "white",
                          border: "none",
                          fontSize: "13px",
                        }}
                        className="px-2"
                      >
                        Update
                      </button>
                    </div>

                    <div className="d-flex">
                      <p className=" bold purity-txt me-2"> Delivery Date: </p>
                      <input
                        type="date"
                        id="estimated"
                        name="estimated"
                        value={date}
                        onChange={handleDate}
                        style={{ height: "20px" }}
                      />
                    </div>
                    <p className=" bold purity-txt">
                      Description : {carts?.remarks ? carts?.remarks : "--"}
                    </p>
                  </div>

                  <div className="col-6 text-end mb-2 mt-2 ">
                    <button
                      className="btn btn-warning"
                      onClick={(e: any) =>
                        handlePlace(e, commoncomment, estimatedDate)
                      }
                    >
                      Place Order
                    </button>
                  </div>
                </div>

                {carts &&
                  carts.data.map((item: any, index: number) => {
                    return (
                      <div>
                        <div className="row">
                          <div className="col-12 border">
                            <h2
                              className="pt-3 pb-3"
                              style={{ marginTop: "", paddingLeft: "10px" }}
                            >
                              {item.level_2_category} | Total Weight :{" "}
                              {item.level_2_total_weight}
                            </h2>
                          </div>
                        </div>

                        <div className="col-12">
                          <div className="row black border content-prev">
                            <div className="col-7 border-bottom border-top p-0 col-bg">
                              <div className="row">
                                <div className="col-8 border-end text-center">
                                  Products
                                </div>
                                <div className="col-4 text-center">
                                  Description
                                </div>
                              </div>
                            </div>
                            <div className="col-5 black border-top border-start border-bottom p-0 col-bg"></div>
                          </div>
                          {carts &&
                            item.orders.map((item: any, index: any) => {
                              return (
                                <CartCard
                                  key={index + item?.item_code}
                                  name={item.item_code}
                                  image={item.image}
                                  remark={item.remark}
                                  wastage={item.wastage}
                                  level_2_category={item.level_2_category}
                                  net_weight={item.net_weight}
                                  order={item.order}
                                  weight={item.weight_abbr}
                                  totalWeight={item.total_weight}
                                  barcodeimage={item.bar_code_image}
                                  bom_factory_code={item.bom_factory_code}
                                  market_design_name={item.market_design_name}
                                  setRev={setRev}
                                />
                              );
                            })}
                        </div>
                      </div>
                    );
                  })}

                <div className="row mt-3 border">
                  <div className="col-md-6 border-top d-flex align-items-center text-start p-0">
                    <textarea
                      className="common-note ps-3 pt-2 ms-2"
                      name="remark"
                      id=""
                      placeholder="Terms & Conditions"
                      value={commoncomment}
                      onChange={(e) => setcommoncomment(e.target.value)}
                    />
                  </div>

                  <div className="col-md-6 border-top mb-2 text-end ">
                    <div className="col-placeorder">
                      <h2 className="mb-2 mt-2 ps-1">
                        Grand Total Weight:-{grandWeight} gm
                      </h2>
                      <br></br>

                      <button
                        className="btn btn-warning"
                        onClick={(e: any) =>
                          handlePlace(e, commoncomment, estimatedDate)
                        }
                        style={{ marginTop: "-30px" }}
                      >
                        Place Order
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container p-0">
                <div className="row my-2 w-100 p-0 text-center">
                  <div className="offset-6 col-md-6 col-6 text-end p-lg-0">
                    <button
                      className="clear_cart_btn"
                      data-toggle="modal"
                      data-target="#confirmationModal"
                      onClick={handleShowConfirmationModal}
                    >
                      Clear Cart
                    </button>
                  </div>
                </div>
              </div>
              <ConfirmationModal
                show={showConfirmationModal}
                handleClose={handleCloseConfirmationModal}
                confirmDelete={(e: any) => confirmDelete(e)}
                title={"Clear Cart"}
                message={"Are you sure? Do you want to clear the cart ?"}
              />
            </div>
          </div>
        ) : (
          <Norecord
            heading="My Cart"
            content="Your cart seems light! Click below to add products of your choice."
            img={img}
          />
        )
      ) : null}

      {/* {cartCount === 0 ? (
        <div className="container mb-4">
          <div className="mt-4">
            <ToastNotification
              setShow={setRev}
              show={rev}
              content="Product Deleted!"
            />
            <div className="col-12 text-center mb-4 mt-4">
              <div className="shopping-cart-heading">
                <h2>My Shopping reorder Cart</h2>
              </div>
            </div>

            <div className="container border">
              <div className="row mb-2">
                <div className="col-6 text-start mb-0 mt-2">
                  <span className=" bold purity-txt me-2">Customer Name:</span>
                  <input
                    type="text"
                    className="px-1"
                    value={editableCustomerName}
                    onChange={handleCustomerName}
                  />

                  <p className=" bold purity-txt">
                    Order Purity : {reorderPurity}
                  </p>

                  <div className="d-flex">
                    <p className=" bold purity-txt me-2"> Delivery Date: </p>
                    <input
                      type="date"
                      id="estimated"
                      name="estimated"
                      value={date}
                      onChange={handleDate}
                      style={{ height: "20px" }}
                    />
                  </div>
                </div>

                <div className="col-6 text-end mb-2 mt-2 ">
                  <button
                    className="btn btn-warning"
                    onClick={(e: any) =>
                      handleReOrderPlace(e, commoncomment, estimatedDate)
                    }
                  >
                    Place Order
                  </button>
                </div>
              </div>

              {orderData &&
                orderData.data.map((itemData: any, index: number) => {
                  return (
                    <div>
                      <div className="row">
                        <div className="col-12 border">
                          <h2
                            className="pt-3 pb-3"
                            style={{ marginTop: "", paddingLeft: "10px" }}
                          >
                            {itemData.level_2_category} | Total Weight :{" "}
                            {itemData.level_2_total_weight}
                          </h2>
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="row black border content-prev">
                          <div className="col-7 border-bottom border-top p-0 col-bg">
                            <div className="row">
                              <div className="col-8 border-end text-center">
                                Products
                              </div>
                              <div className="col-4 text-center">Note</div>
                            </div>
                          </div>
                          <div className="col-5 black border-top border-start border-bottom p-0 col-bg"></div>
                        </div>
                        {itemData.orders.map((item: any, index: any) => {
                          return (
                            <CartCard
                              key={index + item?.item_code}
                              name={item.item_code}
                              image={item.image}
                              remark={item.remark}
                              wastage={item.wastage}
                              level_2_category={item.level_2_category}
                              net_weight={item.net_weight}
                              order={item.order}
                              weight={item.weight_abbr}
                              totalWeight={item.total_weight}
                              barcodeimage={item.bar_code_image}
                              bom_factory_code={item.bom_factory_code}
                              market_design_name={item.market_design_name}
                              setRev={setRev}
                            />
                          );
                        })}
                      </div>
                    </div>
                  );
                })}

              <div className="row mt-3 border">
                <div className="col-md-6 border-top d-flex align-items-center text-start p-0">
                  <textarea
                    className="common-note ps-3 pt-2 ms-2"
                    name="remark"
                    id=""
                    placeholder="Terms & Conditions"
                    value={commoncomment}
                    onChange={(e) => setcommoncomment(e.target.value)}
                  />
                </div>

                <div className="col-md-6 border-top mb-2 text-end ">
                  <div className="col-placeorder">
                    <h2 className="mb-2 mt-2 ps-1">
                      Grand Total Weight:-{reOrderTotalGrandWeight} gm
                    </h2>
                    <br></br>

                    <button
                      className="btn btn-warning"
                      onClick={(e: any) =>
                        handleReOrderPlace(e, commoncomment, estimatedDate)
                      }
                      style={{ marginTop: "-30px" }}
                    >
                      Place Order
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null} */}
    </>
  );
};
export default Cart;
