import { useEffect, useState } from "react";
import { DEFAULT_API_CONFIG } from "../../../services/config/api-config";
import { getRefCodesListAPI } from "../../../services/api/quick-order-api";
import { useNavigate } from "react-router-dom";

export const useBulkOrder = () => {
  const [purityList, setPurityList] = useState<any>([]);
  const [itemList, setItemList] = useState<any>([]);
  const [itemListCustom, setItemListCustom] = useState<any>([]);
  const [itemListBunch, setItemListBunch] = useState<any>([]);
  const [notificationMessage, setNotificationMessage] = useState<string>("");
  const [errorMsgMarket, setErrorMsgMarket] = useState<any>("");
  const [errorMsgCustom, setErrorMsgCustom] = useState<any>("");
  const [errorMsgBunch, setErrorMsgBunch] = useState<any>("");
  const [refCodesList, setRefCodesList] = useState([]);
  const [customerName, setCustomerName] = useState<any>("");
  const [inputValue, setInputValue] = useState("");
  const [customerId, setCustomerId] = useState([]);
  const [totalEstimateWeight, setTotalEstimateWeight] = useState<number>(0);
  const navigate = useNavigate();
  const [bulkOrderNotification, setBulkOrderNotification] =
    useState<boolean>(false);
  const [isBunchWeightDisabled, setIsBunchWeightDisabled] =
    useState<boolean>(false);
  const [itemDetails, setItemDetails] = useState<any[]>([]);
  const [itemSize, setItemSize] = useState<any[]>([]);
  const [perInchWeight, setPerInchWeight] = useState<any[]>([]);
  const [perInchLengthSize, setPerInchLengthSize] = useState<any[]>([]);
  const [formData, setFormData] = useState<any>({
    purity: "",
    transaction_date: "",
    delivery_date: "",
    remarks: "",
    customer: "",
    company: "",
    currency: "",
    marketOrderDetails: [
      {
        item_code: "",
        remark: "",
        uom: "",
        qty: [
          {
            qty: "",
            size: "",
          },
          {
            qty: "",
            size: "",
          },
          {
            qty: "",
            size: "",
          },
          {
            qty: "",
            size: "",
          },
          {
            qty: "",
            size: "",
          },
          {
            qty: "",
            size: "",
          },
          {
            qty: "",
            size: "",
          },
          {
            qty: "",
            size: "",
          },
          {
            qty: "",
            size: "",
          },
          {
            qty: "",
            size: "",
          },
          {
            qty: "",
            size: "",
          },
          {
            qty: "",
            size: "",
          },
          {
            qty: "",
            size: "",
          },
          {
            qty: "",
            size: "",
          },
          {
            qty: "",
            size: "",
          },
          {
            qty: "",
            size: "",
          },
          {
            qty: "",
            size: "",
          },
          {
            qty: "",
            size: "",
          },
          {
            qty: "",
            size: "",
          },
          {
            qty: "",
            size: "",
          },
          {
            qty: "",
            size: "",
          },
          {
            qty: "",
            size: "",
          },
          {
            qty: "",
            size: "",
          },
        ],
      },
    ],
    customMarketOrderDetails: [
      {
        item_code: "",
        qty: [{ qty: "", size: "" }],
        remark: "",
        uom: "",
      },
    ],
    bunchOrderDetails: [
      {
        item_code: "",
        qty: [{ qty: "", size: "" }], //size qty will also be 1
        remark: "",
        uom: "",
        bunch_weight: "", //bunch weight
        weight_per_unit: "", //per inch weight / length
        estimate_bunch_weight: "",
        is_bunch: "",
      },
    ],
  });

  let user: any = localStorage.getItem("user");
  let validUser: any = localStorage.getItem("user");
  validUser = validUser.replace(/^"(.*)"$/, "$1");
  let users: any = user ? JSON.parse(user) : null;
  let login: any = localStorage.getItem("token");
  let accessToken: any = login && JSON.parse(login)?.access_token;

  // Function to handle changes in input fields
  const handleChange = (e: any) => {
    const { name, value } = e?.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleChangeArray = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
    key: string,
    subKey?: number
  ) => {
    const { value } = e.target;
    const updatedMarketOrderDetails = [...formData.marketOrderDetails];

    if (key === "qty") {
      // Allow empty value to enable removal of the number
      if (value === "") {
        setErrorMsgMarket("");
        if (
          subKey !== undefined &&
          updatedMarketOrderDetails[index].qty[subKey]
        ) {
          updatedMarketOrderDetails[index].qty[subKey].qty = value;
          updatedMarketOrderDetails[index].qty[subKey].size = 14 + subKey; // Update size based on the subKey
        }
        if (subKey === 22) {
          updatedMarketOrderDetails[index].qty[22].qty = value;
          updatedMarketOrderDetails[index].qty[22].size = 1; // Update size for 1 inch
        }
      }
      // Ensure qty is a non-zero and positive number
      else if (parseFloat(value) > 0) {
        setErrorMsgMarket("");
        if (
          subKey !== undefined &&
          updatedMarketOrderDetails[index].qty[subKey]
        ) {
          // Update specific size (subKey)
          updatedMarketOrderDetails[index].qty[subKey].qty = value;
          updatedMarketOrderDetails[index].qty[subKey].size = 14 + subKey; // Update size based on the subKey
        }
        if (subKey === 22) {
          // Update 1 Inch Qty
          // console.log("abc", updatedMarketOrderDetails[index].qty[22]);
          updatedMarketOrderDetails[index].qty[22].qty = value;
          updatedMarketOrderDetails[index].qty[22].size = 1; // Update size for 1 inch
        }
      } else {
        setErrorMsgMarket(
          "Quantity value must be a non-zero and positive number."
        );
        return;
      }
    } else {
      // Update other keys
      updatedMarketOrderDetails[index][key] = value;
    }

    setFormData({
      ...formData,
      marketOrderDetails: updatedMarketOrderDetails,
    });
  };

  // Function to add a new row to market order details table
  const addMarketOrderRow = () => {
    setErrorMsgMarket("");
    // Initialize qty as an array with 23 elements (to cover 22 sizes + 1 for 1 inch qty)
    const initialQty = Array.from({ length: 23 }, () => ({
      qty: "",
      size: "",
    }));

    setFormData({
      ...formData,
      marketOrderDetails: [
        ...formData?.marketOrderDetails,
        {
          item_code: "",
          qty: initialQty, // Initialize qty as an array of objects
          uom: "",
        },
      ],
    });
  };

  // Function to delete a new row to market order details table
  const deleteAddMarketOrderRow = (index: number) => {
    setErrorMsgMarket("");
    setFormData((prevState: any) => ({
      ...prevState,
      marketOrderDetails: prevState?.marketOrderDetails.filter(
        (_: any, i: number) => i !== index
      ),
    }));
  };

  const handleChangeArrayCustom = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
    key: string,
    subIndex?: number
  ) => {
    const { value } = e.target;

    setFormData((prevState: any) => ({
      ...prevState,
      customMarketOrderDetails: prevState.customMarketOrderDetails.map(
        (detail: any, i: number) => {
          if (i === index) {
            if (key === "qty" || key === "size") {
              // Ensure qty and size are non-zero and positive
              if (parseFloat(value) > 0) {
                setErrorMsgCustom("");
                return {
                  ...detail,
                  qty: detail.qty.map((qtyItem: any, subIdx: number) =>
                    subIdx === subIndex
                      ? { ...qtyItem, [key]: parseFloat(value) }
                      : qtyItem
                  ),
                };
              } else {
                setErrorMsgCustom(
                  "Quantity and size value must be a non-zero and positive number."
                );
                return detail;
              }
            } else {
              // For other fields like description
              return {
                ...detail,
                [key]: value,
              };
            }
          }
          return detail;
        }
      ),
    }));
  };

  // Function to add a new row to custom market order details table
  const addCustomMarketOrderRow = () => {
    setErrorMsgCustom("");
    setFormData({
      ...formData,
      customMarketOrderDetails: [
        ...formData?.customMarketOrderDetails,
        {
          item_code: "",
          qty: [{ qty: "", size: "" }],
          uom: "",
        },
      ],
    });
  };

  // Function to delete a row from bunchOrderDetails
  const deleteCustomMarketOrderRow = (index: number) => {
    setErrorMsgCustom("");
    setFormData((prevState: any) => ({
      ...prevState,
      customMarketOrderDetails: prevState?.customMarketOrderDetails.filter(
        (_: any, i: number) => i !== index
      ),
    }));
  };

  const fetchItemDetails = async (itemCode: string, index: number) => {
    try {
      const response = await fetch(
        `${DEFAULT_API_CONFIG.url}/api/resource/Item/${itemCode}`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch Item data");
      }
      const data = await response.json();

      const weightPerUnit = data?.data?.weight_per_unit;
      const length = data?.data?.length;

      if (weightPerUnit !== undefined && length !== undefined) {
        setItemDetails((prevDetails) => {
          const newDetails = [...prevDetails, weightPerUnit];
          // newDetails[index] = weightPerUnit;
          return newDetails;
        });

        setItemSize((prevSizes) => {
          const newSizes = [...prevSizes, length];
          // newSizes[index] = length;
          return newSizes;
        });

        const perInchWeightValue =
          length !== 0 ? length / weightPerUnit : "0.000";
        setPerInchWeight((prevWeights) => {
          const newWeights = [...prevWeights, perInchWeightValue];
          // newWeights[index] = perInchWeightValue;
          return newWeights;
        });

        const perInchLengthSize =
          length !== 0 ? weightPerUnit / length : "0.000";
        setPerInchLengthSize((prevWeights) => {
          const newWeights = [...prevWeights, perInchLengthSize];
          // newWeights[index] = perInchWeightValue;
          return newWeights;
        });
      } else {
        setItemDetails((prevDetails) => {
          const newDetails = [...prevDetails, null];
          // newDetails[index] = null;
          return newDetails;
        });
        setItemSize((prevSizes) => {
          const newSizes = [...prevSizes, null];
          // newSizes[index] = null;
          return newSizes;
        });
        setPerInchWeight((prevWeights) => {
          const newWeights = [...prevWeights, "0.00"];
          // newWeights[index] = "0.000";
          return newWeights;
        });
      }
    } catch (error) {
      // console.error("Error fetching item details:", error);
    }
  };

  useEffect(() => {
    // console.log("itemDetails updated:", itemDetails);
    // console.log("itemSize updated:", itemSize);
    // console.log("perInchWeight size/gold updated:", perInchWeight);
    // console.log("perInchLength gold/size updated:", perInchLengthSize);
  }, [itemDetails, itemSize, perInchWeight, perInchLengthSize]);

  const handleChangeArrayBunch = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
    key: string,
    subIndex?: number
  ) => {
    const { value } = e.target;
    // Handle both positive values and empty strings for clearing input
    if (parseFloat(value) > 0 || value === "") {
      setErrorMsgBunch("");

      if (key === "bunch_weight") {
        setIsBunchWeightDisabled(false);
        const total_weight = parseFloat(value);

        setFormData((prevState: any) => ({
          ...prevState,
          bunchOrderDetails: prevState.bunchOrderDetails.map(
            (detail: any, i: number) =>
              i === index
                ? {
                    ...detail,
                    [key]: value,
                    estimate_bunch_weight: total_weight,
                    is_bunch: 1, // Ensure is_bunch is set to 1
                    item_code: detail.item_code,
                    weight_per_unit: perInchWeight[index],
                    qty: detail.qty.map((qtyItem: any, subIdx: number) =>
                      subIdx === subIndex
                        ? {
                            ...qtyItem,
                            size: !isNaN(total_weight * perInchWeight[index])
                              ? (total_weight * perInchWeight[index]).toFixed(3)
                              : "",
                            // size: 1,
                            qty: 1,
                          }
                        : {
                            // size: 1,
                            size: !isNaN(total_weight * perInchWeight[index])
                              ? (total_weight * perInchWeight[index]).toFixed(2)
                              : "",
                            qty: 1,
                          }
                    ),
                  }
                : detail
          ),
        }));
      } else if (key === "qty") {
        // When updating qty, set qty to 1 and ensure to maintain the size as well
        setFormData((prevState: any) => ({
          ...prevState,
          bunchOrderDetails: prevState.bunchOrderDetails.map(
            (detail: any, i: number) =>
              i === index
                ? {
                    ...detail,
                    is_bunch: 1, // Ensure is_bunch is set to 1
                    qty: detail.qty.map((qtyItem: any, subIdx: number) =>
                      subIdx === subIndex
                        ? {
                            ...qtyItem,
                            qty: 1, // Ensure qty is always 1
                            size: 1,
                          }
                        : qtyItem
                    ),
                    // weight_per_unit: perInchWeight[0],
                    item_code: detail.item_code,
                    bunch_weight: detail.bunch_weight,
                    estimate_bunch_weight: detail.total_weight,
                  }
                : detail
          ),
        }));
      }
      if (key === "size") {
        setIsBunchWeightDisabled(true);
        // When updating size, ensure to maintain the qty as well
        setFormData((prevState: any) => ({
          ...prevState,
          bunchOrderDetails: prevState.bunchOrderDetails.map(
            (detail: any, i: number) =>
              i === index
                ? {
                    ...detail,
                    is_bunch: 1, // Ensure is_bunch is set to 1
                    qty: detail.qty.map((qtyItem: any, subIdx: number) =>
                      subIdx === subIndex
                        ? {
                            ...qtyItem,
                            size: !isNaN(parseFloat(value))
                              ? parseFloat(value)
                              : "",
                            qty: 1, // Ensure qty remains 1
                          }
                        : qtyItem
                    ),
                    weight_per_unit: perInchLengthSize[0],
                    item_code: detail.item_code,
                    bunch_weight: detail.bunch_weight,
                    // estimate_bunch_weight: (
                    //   parseFloat(value) * perInchWeight[0]
                    // ).toFixed(3),
                    estimate_bunch_weight: (
                      parseFloat(value) * perInchLengthSize[0]
                    ).toFixed(2),
                  }
                : detail
          ),
        }));
      } else {
        // Update formData normally for other fields
        setFormData((prevState: any) => ({
          ...prevState,
          bunchOrderDetails: prevState.bunchOrderDetails.map(
            (detail: any, i: number) =>
              i === index ? { ...detail, [key]: value, is_bunch: 1 } : detail
          ),
        }));
      }
    } else if (parseFloat(value) <= 0) {
      setErrorMsgBunch(
        "Quantity and size value must be a non-zero and positive number."
      );
    } else {
      // Update formData normally for other fields
      setFormData((prevState: any) => ({
        ...prevState,
        bunchOrderDetails: prevState.bunchOrderDetails.map(
          (detail: any, i: number) =>
            i === index ? { ...detail, [key]: value, is_bunch: 1 } : detail
        ),
      }));
    }
  };

  // Calculate Total Estimate Bunch Weight for all rows
  const calculateTotalEstimateBunchWeight = () => {
    let total = 0;
    formData?.bunchOrderDetails?.forEach((row: any) => {
      const estimateBunchWeight = parseFloat(row?.estimate_bunch_weight);
      if (!isNaN(estimateBunchWeight)) {
        total += estimateBunchWeight;
      }
    });
    return total;
  };

  useEffect(() => {
    // Update total estimate weight whenever formData changes
    const totalWeight = calculateTotalEstimateBunchWeight();
    setTotalEstimateWeight(totalWeight);
  }, [formData]);

  // Function to add a new row to bunch order details table
  const addBunchOrderRow = () => {
    setErrorMsgBunch("");
    setFormData({
      ...formData,
      bunchOrderDetails: [
        ...formData?.bunchOrderDetails,
        {
          item_code: "",
          qty: [{ qty: "", size: "" }], // Initialize qty as an array of objects
          uom: "",
          bunch_weight: "",
          weight_per_unit: "",
          estimate_bunch_weight: "",
          is_bunch: "",
        },
      ],
    });
  };

  // Function to delete a row from bunchOrderDetails
  const deleteBunchOrderRow = (index: number) => {
    setErrorMsgBunch("");
    setFormData((prevState: any) => ({
      ...prevState,
      bunchOrderDetails: prevState?.bunchOrderDetails.filter(
        (_: any, i: number) => i !== index
      ),
    }));
  };

  // purity
  const fetchPurityData = async () => {
    try {
      const response = await fetch(
        `${DEFAULT_API_CONFIG.url}/api/resource/Purity`,
        {
          headers: {
            Authorization: accessToken, // Your authorization token
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch Item data");
      }

      const data = await response.json();
      setPurityList(data?.data);
    } catch (error: any) {}
  };

  // Item
  const fetchItemData = async () => {
    try {
      const response = await fetch(
        `${DEFAULT_API_CONFIG.url}/api/resource/Item`,
        {
          headers: {
            Authorization: accessToken, // Your authorization token
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch Item data");
      }

      const data = await response.json();
      setItemList(data?.data);
      setItemListCustom(data?.data);
      setItemListBunch(data?.data);
    } catch (error: any) {}
  };

  const handleInputChange = (e: any, field: any) => {
    let inputValue: any = e.target.value;
    setCustomerName(inputValue);
  };
  const handleCustomerName: any = async () => {
    let refCodesList: any = await getRefCodesListAPI(customerName);
    if (
      refCodesList?.status === 200 &&
      refCodesList?.data?.message?.length > 0
    ) {
      setRefCodesList(refCodesList.data.message);
      // setDisabledInputField(false);
    } else {
      setRefCodesList([]);
    }
  };

  const getCustomer: any = async () => {
    try {
      const response = await fetch(
        `${DEFAULT_API_CONFIG.url}/api/method/digitalcatalog_api_erpnext.api.utils.get_customer?user=${validUser}`,
        {
          headers: {
            Authorization: accessToken,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch Item data");
      }

      const data = await response.json();

      setCustomerId(data?.message);
    } catch (error: any) {}
  };

  useEffect(() => {
    getCustomer();
  }, []);

  useEffect(() => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    const todayString = `${year}-${month}-${day}`;

    // Update formData.transaction_date with today's date
    handleChange({
      target: {
        name: "transaction_date",
        value: todayString,
      },
    });
  }, []);

  // Function to calculate delivery date
  const calculateDeliveryDate = (transactionDate: string) => {
    const date = new Date(transactionDate);
    date.setDate(date.getDate() + 15);
    return date.toISOString().split("T")[0];
  };

  // Update delivery date on transaction date change
  useEffect(() => {
    if (formData.transaction_date) {
      const deliveryDate = calculateDeliveryDate(formData.transaction_date);
      setFormData((prev: any) => ({
        ...prev,
        delivery_date: deliveryDate,
      }));
    }
  }, [formData.transaction_date]);

  // function to POST all from data
  const handleSubmit = async () => {
    try {
      const mappedMarketItems = formData.marketOrderDetails
        .map((item: any) => ({
          item_code: item.item_code,
          qty: item.qty.filter(
            (q: any) =>
              q.qty !== "" &&
              q.qty !== null &&
              q.qty !== undefined &&
              q.size !== "" &&
              q.size !== null &&
              q.size !== undefined
          ),
          remark: item.remark,
          uom: formData?.purity,
        }))
        .filter((item: any) => item.qty.length > 0);
      const mappedCustomItems = formData.customMarketOrderDetails
        .map((item: any) => ({
          item_code: item.item_code,
          qty: item.qty.filter(
            (q: any) =>
              q.qty !== "" &&
              q.qty !== null &&
              q.qty !== undefined &&
              q.size !== "" &&
              q.size !== null &&
              q.size !== undefined
          ),
          remark: item.remark,
          uom: formData?.purity,
        }))
        .filter((item: any) => item.qty.length > 0);
      const mappedBunchItems = formData.bunchOrderDetails
        .map((item: any) => ({
          item_code: item.item_code,
          qty: item.qty.filter(
            (q: any) =>
              q.qty !== "" &&
              q.qty !== null &&
              q.qty !== undefined &&
              q.size !== "" &&
              q.size !== null &&
              q.size !== undefined
          ),

          remark: item.remark,
          uom: formData?.purity,
          weight_per_unit: item.weight_per_unit,
          per_unit_weight: item.weight_per_unit,
          estimate_bunch_weight: item.estimate_bunch_weight,
          is_bunch: item.is_bunch,
          bunch_weight: parseInt(item.bunch_weight), //bunch weight
        }))
        .filter((item: any) => item.qty.length > 0);

      // Combine all items
      const mergedItems = [
        ...mappedMarketItems,
        ...mappedCustomItems,
        ...mappedBunchItems,
      ];

      // Filter items to remove those without qty
      const filteredItems = mergedItems.filter((item) => {
        return item.qty !== "" && item.qty !== null && item.qty !== undefined;
      });

      // Construct final mappedItemsAllArray
      const mappedItemsAllArray = filteredItems.flatMap((item: any) => {
        const { qty, ...rest } = item;
        return qty.map((q: any) => ({
          item_code: rest.item_code,
          qty_size: [
            {
              qty: q.qty,
              size: q.size,
              estimate_bunch_weight: rest.estimate_bunch_weight,
              per_unit_weight: rest.weight_per_unit,
              is_bunch: rest.is_bunch,
            },
          ],
          remark: rest.remark,
          uom: rest.uom || formData.purity,
          // weight_per_unit: rest.weight_per_unit,
          weight_per_unit: "",
          estimate_bunch_weight: rest.estimate_bunch_weight,
          is_bunch: rest.is_bunch,
          bunch_weight: rest.bunch_weight, //bunch weight
        }));
      });

      // Call API to place the order using formData
      const response = await fetch(
        // `${DEFAULT_API_CONFIG.url}api/method/digitalcatalog_api_erpnext.api.order.create_sales_order`,

        `${DEFAULT_API_CONFIG.url}/api/method/digitalcatalog_api_erpnext.api.order.create_bulk_quotation`,

        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: accessToken,
          },
          body: JSON.stringify({
            // name: salesOrderData?.name,
            // naming_series: "SAL-ORD-.YYYY.-",
            cust_name: formData?.customer,
            purity: formData?.purity,
            transaction_date: formData?.transaction_date,
            company: "ARC",
            contact_email: users,
            conversion_rate: "",
            currency: "INR",
            customer: customerId,
            customer_group: "",
            customer_name: customerId,
            delivery_date: formData?.delivery_date,
            remarks: formData?.remarks,
            order_type: "Shopping Cart",
            party_name: customerId,
            plc_conversion_rate: "",
            price_list_currency: "INR",
            selling_price_list: "Standard Selling",
            territory: "",
            user: users,
            // doctype: "Sales Order",
            // items: mappedItems,
            items: mappedItemsAllArray,
          }),
        }
      );
      const data = await response.json();

      if (!response.ok || (data.message && data.message.msg === "error")) {
        // Handle error response
        // console.log("setFormData", formData);
        const errorMsg = data.message?.error || "Failed to place the order";
        setBulkOrderNotification(true);
        setNotificationMessage(errorMsg);
      } else {
        setBulkOrderNotification(true);
        const successMsg =
          data.message?.data?.message || "Order placed successfully";
        setNotificationMessage(successMsg);
        // Navigate to the order list page on successful order placement
        // navigate(`/order-list`);
        navigate(`/cart`);
      }
    } catch (error: any) {
      // Handle any other errors
      setBulkOrderNotification(true);
      setNotificationMessage(error.message);
    }
  };

  useEffect(() => {
    fetchPurityData();
    fetchItemData();
  }, []);

  return {
    formData,
    handleChange,
    handleChangeArray,
    addMarketOrderRow,
    deleteAddMarketOrderRow,
    addCustomMarketOrderRow,
    addBunchOrderRow,
    deleteBunchOrderRow,
    deleteCustomMarketOrderRow,
    handleSubmit,
    itemList,
    itemListBunch,
    itemListCustom,
    handleChangeArrayCustom,
    handleChangeArrayBunch,
    purityList,
    notificationMessage,
    bulkOrderNotification,
    setBulkOrderNotification,
    errorMsgMarket,
    errorMsgCustom,
    errorMsgBunch,
    setFormData,
    refCodesList,
    inputValue,
    setInputValue,
    handleInputChange,
    handleCustomerName,
    totalEstimateWeight,
    fetchItemDetails,
    perInchWeight,
    perInchLengthSize,
    isBunchWeightDisabled,
  };
};
