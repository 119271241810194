import React, { useState } from "react";
import useReportList from "./review-report/components/report-list-hook";
import { Link } from "react-router-dom";
import { Norecord } from "../../components/norecord";
import img from "../../assets/img/order-list.png";
import ReportTotalRow from "./review-report/report-total-row";
import ReportViewModal from "../../components/report/ReportViewModal";

const ReportList = () => {
  const { reportListingData, calculationRowData } = useReportList();

  const path = window.location.pathname;
  const pathSegments = path.split("/");
  const lastParameter = pathSegments[pathSegments.length - 1];
  const formattedHeading = lastParameter
    ?.split("-")
    ?.map((word: any) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");

  const [show, setShow] = useState(false);
  const [reviewData, setReviewData] = useState<any>("");

  const handleShow: any = (review: any) => {
    setShow(true);
    setReviewData(review);
  };

  const updateOrderId: any = (name: any) => {
    let updateOrderId: any = name?.split("-");
    let lastPartOfOrderId: any = updateOrderId[updateOrderId?.length - 1];
    return lastPartOfOrderId;
  };

  return (
    <div className="container pt-5 ">
      <div className="container pt-5">
        <div className="order-list-cont">
          <div className="row pb-3">
            {reportListingData?.length > 0 ? (
              <>
                <div className="order-heading text-center">
                  <h2>{formattedHeading}</h2>
                </div>
                <div className="report-table-container">
                  <div className="report-table-wrapper">
                    <table>
                      <thead className="fixed-header">
                        <tr style={{ fontSize: "16px !important" }}>
                          <th>Sr.No.</th>
                          <th>Transaction Date</th>
                          <th>Customer Name</th>
                          <th>Order Id</th>
                          <th>Product</th>
                          <th>Item Name</th>
                          <th>Delivery date</th>
                          <th>Quantity</th>
                          <th>Total Weight</th>
                          <th>Total Net Weight</th>
                          <th>Status</th>
                          {lastParameter === "review-report" && <th>Review</th>}
                        </tr>
                      </thead>

                      {reportListingData?.length > 0 &&
                        reportListingData?.map((report: any, index: any) => (
                          <>
                            <tr>
                              <td>{index + 1}</td>
                              <td>
                                <strong>{report.transaction_date}</strong>
                              </td>
                              <td>
                                <strong>{report.customer_name}</strong>
                              </td>
                              <td>
                                <Link
                                  to={"/order-detail/" + report.name}
                                  className="order-link"
                                >
                                  <b>{updateOrderId(report.name)}</b>
                                </Link>
                              </td>

                              <td>
                                <strong>{report.product}</strong>
                              </td>
                              <td>
                                <strong>{report.item_name}</strong>
                              </td>

                              <td className="">
                                <strong>{report.delivery_date}</strong>
                              </td>
                              <td className="">
                                <strong>{report.qty}</strong>
                              </td>
                              <td className="">
                                <strong>{report.total_weight}</strong>
                              </td>
                              <td className="">
                                <strong>{report.total_net_weight}</strong>
                              </td>
                              <td className="">
                                <strong>{report.manufacturing_status}</strong>
                              </td>
                              {lastParameter === "review-report" && (
                                <td className="">
                                  <p>
                                    {Object?.keys(report.customer_review)
                                      ?.length > 0 && (
                                      <>
                                        {Object?.keys(report.customer_review)
                                          ?.length > 15 ? (
                                          <a
                                            className="link-opacity-100 text-dark"
                                            style={{ cursor: "pointer" }}
                                            title={report.customer_review}
                                            onClick={() =>
                                              handleShow(report.customer_review)
                                            }
                                          >
                                            {report.customer_review.slice(
                                              0,
                                              20
                                            )}
                                            ...
                                          </a>
                                        ) : (
                                          <>{report.customer_review}</>
                                        )}
                                      </>
                                    )}
                                  </p>
                                </td>
                              )}
                            </tr>
                          </>
                        ))}
                      <ReportTotalRow calculationRowData={calculationRowData} />
                    </table>
                  </div>
                </div>
              </>
            ) : (
              <Norecord
                heading={formattedHeading}
                img={img}
                content="Sorry for disappointing you! We’re unable to find any relevant data"
              />
            )}
          </div>
        </div>
      </div>

      <ReportViewModal show={show} setShow={setShow} reviewData={reviewData} />
    </div>
  );
};

export default ReportList;
