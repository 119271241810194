import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import api from "../../services/api/auth-api";
import { handleResponse } from "../../services/config/handle-response";
import { CONSTANT } from "../../utils/constant";
import { useState } from "react";

interface RepoLoginState {
  user: any;
  error: any;
  isLoggedIn: boolean;
  isLoggingIn: boolean;
  // token: string;
}

const initialState: RepoLoginState = {
  user: {},
  error: "",
  isLoggedIn: false,
  isLoggingIn: false,
  // token: "",
};

const loginScreen = createSlice({
  name: "login",
  initialState,
  reducers: {
    loginUserSuccess(state, action: PayloadAction<RepoLoginState>) {
      localStorage.setItem("token", JSON.stringify(action.payload));
      state.user = action.payload;
      state.error = "";
      state.isLoggedIn = true;
      state.isLoggingIn = false;
    },
    loginUserFailed(state) {
      state.error = "Incorrect Email-Id or Password";
      state.isLoggedIn = false;
      state.isLoggingIn = false;
    },
    loginUserDisable(state) {
      state.error = "Your account has been disabled. Please contact Admin!";
      state.isLoggedIn = false;
      state.isLoggingIn = false;
    },
    loggingIn(state) {
      state.user = null;
      state.error = "";
      state.isLoggedIn = false;
      state.isLoggingIn = true;
    },
    setLogoutUser(state) {
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      localStorage.removeItem("localPurity");
      state.user = null;
      state.error = "";
      state.isLoggedIn = false;
      state.isLoggingIn = false;
    },
    isLoading(state) {
      state.isLoggedIn = false;
      state.isLoggingIn = false;
    },
  },
});

const {
  loginUserSuccess,
  loginUserFailed,
  loginUserDisable,
  loggingIn,
  setLogoutUser,
  isLoading,
} = loginScreen.actions;

export const loginUserAPI =
  (request: any): any =>
  async (dispatch: any) => {
    try {
      localStorage.setItem("user", JSON.stringify(request.usr));
      dispatch(loggingIn());
      const response = await api.login(request);
      console.log(response);
      const result = dispatch(handleResponse(response));
      console.log(result, "request");
      if (result && result.access_token && result.msg === "Login Successful!") {
        dispatch(loginUserSuccess(result));
      } else if (
        result &&
        result.msg === "Cannot login with a disabled user!"
      ) {
        dispatch(loginUserDisable());
      } else {
        dispatch(loginUserFailed());
      }
    } catch (err) {}
  };

export const isLoadingLogin = (): any => async (dispatch: any) => {
  dispatch(isLoading());
};

export const logoutUser = (): any => async (dispatch: any) => {
  try {
    dispatch(setLogoutUser());
  } catch (err) {}
};

// export const getUser=(state: any)=>state.login.isLoggedIn;
export default loginScreen.reducer;
