import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import chainImage from "../../assets/img/Chains.png";
import ornamentImage from "../../assets/img/Ornaments.png";
import turkeyImage from "../../assets/img/Turkey.jpg";
import exportImage from "../../assets/img/export.jpg";
import newArrivalImg from "../../assets/img/new-arrival-no-bg.png";
import exhibitionImage from "../../assets/img/exhibition_img.png";
const Category = () => {
  // const { categories } = useCategory();
  return (
    <>
      <div className=" container main-container" style={{ marginTop: "60px " }}>
        {/* <div className="d-flex justify-content-end align-items-end">
          <img
            src={NewArrivalGIF}
            width={100}
            height={100}
            onClick={() => {
              console.log("check");
            }}
          />
        </div> */}
        <div className="text-end">
          <Link to="/new-arrival?page=1">
            <img
              src={newArrivalImg}
              alt=""
              className="img-fluid"
              style={{
                // borderRadius: "5px",
                height: "145px",
                // width: "100%",
                objectFit: "cover",
              }}
            />
          </Link>
        </div>
        <div className="row">
          <div className="col-md-6 mb-3">
            <Link to="/categories/chains">
              <img
                src={chainImage}
                alt=""
                className="img-fluid"
                style={{
                  borderRadius: "5px",
                  height: "155px",
                  width: "100%",
                  objectFit: "cover",
                }}
              />
            </Link>
          </div>

          <div className="col-md-6">
            <Link to="/categories/ornaments">
              <img
                src={ornamentImage}
                alt=""
                className="img-fluid"
                style={{
                  borderRadius: "5px",
                  height: "155px",
                  width: "100%",
                  objectFit: "cover",
                }}
              />
            </Link>
          </div>
          <div className="col-md-6">
            <Link to="/categories/turkey">
              <img
                src={turkeyImage}
                alt=""
                className="img-fluid"
                style={{
                  borderRadius: "5px",
                  height: "155px",
                  width: "100%",
                  objectFit: "cover",
                }}
              />
            </Link>
          </div>
          <div className="col-md-6">
            <Link to="/categories/export">
              <img
                src={exportImage}
                alt=""
                className="img-fluid"
                style={{
                  borderRadius: "5px",
                  height: "155px",
                  width: "100%",
                  objectFit: "cover",
                }}
              />
            </Link>
          </div>
          <div className="col-md-12 mt-3 border-primary">
            <Link to="/categories/exhibition">
              <img
                src={exhibitionImage}
                alt=""
                className="img-fluid"
                style={{
                  borderRadius: "5px",
                  height: "155px",
                  width: "100%",
                  objectFit: "cover",
                }}
              />
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};
export default Category;
