import React, { useState } from "react";
import { Formik, Field, Form as FormikFrom, ErrorMessage } from "formik";
import { Form, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { validation } from "./validation";
import logo from "../../assets/img/logo.png";
import { loginUserAPI } from "../../store/screens/login";
import "../../assets/css/login.css";
import { RootState } from "../../store/root-reducer";
import { useNavigate } from "react-router-dom";
import { useLogin } from "./components/login-hooks";

function Login() {
  const dispatch = useDispatch();
  const { login, isRevaled, hideshow } = useLogin();

  return (
    <div>
      <Formik
        initialValues={{
          usr: "",
          pwd: "",
        }}
        validationSchema={validation}
        onSubmit={(values: any) => {
          dispatch(loginUserAPI(values));
        }}
      >
        {({ errors, touched, handleChange, isValid, handleBlur }) => (
          <FormikFrom>
            <div className="form-wrapper" id="wrapper-login">
              <div className="content-wrapper" id="content-signin">
                {/* <h1>Login</h1> */}
                <div className="row justify-content-center">
                  <div className="col-lg-3 col-md-4  main-column">
                    <div className="row ">
                      <div className="col-12">
                        {/* <h2 className="mb-5 signin mt-1">Sign-In</h2> */}
                        <div className="img ">
                          <img
                            src={logo}
                            width={60}
                            className="img-fluid mx-auto d-block mb-4"
                            alt=""
                          />
                        </div>
                        {login.error && (
                          <label className="error">{login.error}</label>
                        )}

                        <Form.Group controlId="formEmail">
                          <Form.Label>Email ID </Form.Label>
                          <Form.Control
                            onChange={handleChange}
                            type="text"
                            name="usr"
                            placeholder="Enter Email"
                            className="emailfield"
                            onBlur={handleBlur}
                            // isInvalid={errors && errors.usr ? true : false}
                          />
                          <div className="empty">
                            <ErrorMessage name="usr" />
                          </div>

                          {/* <Form.Control.Feedback type="invalid">
                            {errors && errors.usr}
                          </Form.Control.Feedback> */}
                        </Form.Group>
                      </div>
                    </div>
                    <div className="row mt-1 ">
                      <div className="col-12">
                        <Form.Group controlId="formPassword">
                          <Form.Label>Password </Form.Label>
                          <div className="input-group">
                            <Form.Control
                              onChange={handleChange}
                              type={isRevaled ? "text" : "password"}
                              name="pwd"
                              placeholder="Enter Password"
                              onBlur={handleBlur}
                              // isInvalid={errors && errors.pwd ? true : false}
                            />
                            <span className="input-group-text">
                              {isRevaled ? (
                                <i
                                  className="fa fa-eye"
                                  onClick={() => hideshow()}
                                />
                              ) : (
                                <i
                                  className="fa fa-eye-slash"
                                  onClick={() => hideshow()}
                                />
                              )}
                            </span>
                          </div>
                          <div className="empty">
                            <ErrorMessage name="pwd" />
                          </div>

                          {/* <Form.Control.Feedback type="invalid">
                            {errors && errors.pwd}
                          </Form.Control.Feedback> */}
                        </Form.Group>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-12 d-flex justify-content-center">
                        <button type="submit" className="btn-login mb-2 mt-2">
                          LOGIN
                        </button>
                        {/* <p className="change mt-2">
                            No account? <Link to="/register">Sign up</Link>
                        </p> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </FormikFrom>
        )}
      </Formik>
    </div>
  );
}

export default Login;
