import { callAPI } from "../config/api-core";
import { OrderAPIMethods } from "../methods/order-api-methods";

export const postCancelOrder = async (request: any): Promise<any> => {
  console.log(request, "data");
  let login = localStorage.getItem("token");
  let header = {
    Authorization: login && JSON.parse(login)?.access_token,
  };
  const response = await callAPI(
    OrderAPIMethods.cancelOrder,
    false,
    request,
    header,
    false,
    false
  );

  return response;
};
